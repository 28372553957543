
<mat-card  class="mb-2">
    <mat-card-content>
        <form [formGroup]="userform" >
            <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance={{themeConfigInput}}>
                            <mat-label>Associate Name</mat-label>
                            <mat-select placeholder="Select Associate" [disabled]="disableAssociateName" formControlName="EmpId" name="EmpId"
                            (selectionChange)=getCurrentUserDetails($event.value) required>
                            <mat-option *ngFor="let user of _usersList" [value]="user.value">
                                {{user.label}}</mat-option>
                        </mat-select>
            
                            <mat-error *ngIf="userform.get('EmpId').hasError('required') ">
                                <i style="color: red" class="fa fa-exclamation-circle"></i>
                              <span style="color: red">Assosiate Name Required</span>
                            </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>

           <mat-card class="col-md-12 floating-main" *ngIf="showAllocationHistoryGrid">
                        <mat-card-content>
                            
                          <div class="table-responsive">

                            <table mat-table [dataSource]="dataSource">

                                    <ng-container matColumnDef="DateOfJoin">
                                      <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Date of Joining </th>
                                      <td mat-cell *matCellDef="let element">{{element.DateOfJoin}}</td>
                                    </ng-container>
                  
                                    <ng-container matColumnDef="Designation">
                                      <th mat-header-cell *matHeaderCellDef  class="font-weight-bold">Designation</th>
                                      <td mat-cell *matCellDef="let element">{{element.Designation}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="ReportingManager">
                                            <th mat-header-cell *matHeaderCellDef class="font-weight-bold">Reporting Manager</th>
                                            <td mat-cell *matCellDef="let element">{{element.ReportingManager}}</td>
                                    </ng-container>
                  
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                              </table>




                          </div>
                        </mat-card-content>
            </mat-card>
                    
<mat-card class="col-md-12">
    <mat-card-content>
        <div>
            <form [formGroup]="terminationForm">
                    <div class="col-md-6">
                        <mat-form-field appearance={{themeConfigInput}}>
                            <mat-label>Cause Category</mat-label>
                            <mat-select formControlName="IsActive"
                                required>
                                <mat-option *ngFor="let aspect of exitreasonlist" [value]="aspect.value">{{aspect.label}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="terminationForm.get('IsActive').hasError('required')">
                                <i style="color: red" class="fa fa-exclamation-circle"></i>
                              <span style="color: red">Cause Category Required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field appearance="{{themeConfigInput}}">
                            <mat-label>Last Working Date</mat-label>
                            <input formControlName="LastWorkingDate" matInput [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="terminationForm.get('LastWorkingDate').hasError('required')">
                                <i style="color: red" class="fa fa-exclamation-circle"></i>
                                <span style="color: red">Last Working Date Required.</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance={{themeConfigInput}}>
                            <mat-label>Cause Details</mat-label>
                            <textarea matInput formControlName="associateRemarks" name="associateRemarks"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 text-right">
                        <button mat-stroked-button (click)="goto()" mat-flat-button color="primary">Evidence</button>
                    </div>
                    <div class="col-md-12 text-right">
                        <button (click)="clearValues()" mat-stroked-button><mat-icon>clear
                        </mat-icon>Clear</button>
                        <button  type="submit" (click)="onSubmit()" mat-flat-button color="primary">Submit</button>
                    </div>
        </form>
    </div>
    </mat-card-content>
</mat-card>
