<div class="deleteConfirmationDialogBox">
    <mat-dialog-content>
      <div class="labelValue">{{ message }}</div>
    </mat-dialog-content>
    <mat-dialog-actions style="float: right">
      <button mat-raised-button mat-dialog-close tabindex="-1">{{ cancelButtonText }}</button>
      <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{ confirmButtonText }}</button>
    </mat-dialog-actions>
  </div>
  
