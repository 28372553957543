<h1 mat-dialog-title> Tag List</h1>
<div mat-dialog-content>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="isNewTagList">
        <mat-radio-button 
        name="group2" (click)="onRadioClick()" name="isNewTagList"
        [checked]='true' [value]="0">New Tag List</mat-radio-button>
        <mat-radio-button  
        name="group2" value="1"  name="isNewTagList"
        (click)="onRadioClick()" [value]="1">Existing Tag</mat-radio-button>
    </mat-radio-group>

<form [formGroup]="skillsearchForm">
    <div class="col-md-6">
        <mat-form-field appearance={{themeConfigInput}}>
            <mat-label>List Name<span class="text-danger">*</span></mat-label>
            <mat-select  *ngIf="isNewTagList== '1'" 
            formControlName="TagListId" required>
                <mat-option *ngFor="let user of tagListNames" [value]="user.value">
                    {{user.label}}</mat-option>
            </mat-select>

            <input  *ngIf="isNewTagList== '0'" placeholder="tag list name" matInput
            formControlName="TagListName" />

        </mat-form-field>

        <div class="col-md-12 text-right">
            <button mat-button (click)="onNoClick()">Close</button>
            <button type="submit" (click)="saveToTagList()" mat-flat-button color="primary">
                Save</button>
        </div>
    </div>
</form>
</div>