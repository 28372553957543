<div class="row">
  <div class="col-md-3">
    <mat-checkbox #resigned checked (change)="CheckResigned($event)">Include Ex-Employees</mat-checkbox>

  </div>
  
  <div class="col-md-9">
    <span style="float: right;">    
      <a *ngIf="reportDetails.length>0"
        (click)="exportAsXLSX()" title="Export to Excel">
        <div class="exportToExcel"></div>
    </a>
    </span>

    </div>    
</div> 
<br>
<mat-card>
<mat-card-content>
  <div class="table-responsive">
    <table #table mat-table matTableExporter [dataSource]="dataSource" matSort class="mat-elevation-z8" #exporter="matTableExporter">
     
      <ng-container matColumnDef="AssociateCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-left">Associate Code</th>
        <td mat-cell *matCellDef="let element" class="td-left"> {{element.AssociateCode}} </td>
      </ng-container>

    <ng-container matColumnDef="AssociateName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-left">Associate Name</th>
        <td mat-cell *matCellDef="let element" class="td-left"> {{element.AssociateName}} </td>
      </ng-container>

    <ng-container matColumnDef="DateOfJoining">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold th-center">Date Of Joining</th>
      <td mat-cell *matCellDef="let element" class="td-center"> {{element.DateOfJoining | date:'MM/dd/yyyy'}} </td>
    </ng-container>
    
    <ng-container matColumnDef="EmploymentStartDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-center">Employment Start Date</th>
      <td mat-cell *matCellDef="let element" class="td-center"> {{element.EmploymentStartDate | date:'MM/dd/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="LastWorkingDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-center">Last Working Date</th>
      <td mat-cell *matCellDef="let element" class="td-center"> {{element.LastWorkingDate | date:'MM/dd/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="ProjectsWorked">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold th-left">Projects Worked</th>
      <td mat-cell *matCellDef="let element" class="hidden-text" matTooltip="{{element.ProjectsWorked}}"> {{element.ProjectsWorked}} </td>
    </ng-container> 
    <ng-container matColumnDef="TotalWorkedDays"> 
      <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-right">Total Worked Days</th>
      <td mat-cell *matCellDef="let element" class="td-center"> {{element.TotalWorkedDays}} </td>
    </ng-container>  
    <ng-container matColumnDef="TimeTakenForFirstBilling">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold th-right">Days Taken For First Billing</th>
      <td mat-cell *matCellDef="let element" class="td-center"> {{element.TimeTakenForBillable}} </td>
    </ng-container> 
    <ng-container matColumnDef="LastBillingDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-center">Last Billing Date</th>
      <td mat-cell *matCellDef="let element" class="td-center"> {{element.LastBillingDate | date:'MM/dd/yyyy'}} </td>
    </ng-container>   
    <ng-container matColumnDef="TotalBillingDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-right">Total Billing Days</th>
        <td mat-cell *matCellDef="let element" class="td-center"> {{element.TotalBillingDays}} </td>
      </ng-container>
      <ng-container matColumnDef="TotalNonBillingDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-right">Total Non-Billing Days</th>
        <td mat-cell *matCellDef="let element" class="td-center"> {{element.TotalNonBillingDays}} </td>
      </ng-container>
      <ng-container matColumnDef="BillingDaysPercentage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-right">Billing Days (%)</th>
        <td mat-cell *matCellDef="let element" class="td-center"> {{element.BillingDaysPercentage}} </td>
      </ng-container>
      <ng-container matColumnDef="Experience">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-right">Experience</th>
        <td mat-cell *matCellDef="let element" class="td-center"> {{element.ExperienceExcludingCareerBreak}} </td>
      </ng-container>
      <ng-container matColumnDef="Technology">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-right">Technology</th>
        <td mat-cell *matCellDef="let element" class="hidden-text" matTooltip="{{element.CompetencyGroup}}"> {{element.CompetencyGroup}} </td>
      </ng-container>
      <ng-container matColumnDef="Skills">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-right">Skills</th>
        <td class="hidden-text" matTooltip="{{element.Skills}}"  mat-cell *matCellDef="let element" > {{element.Skills}} </td>
      </ng-container>
      <ng-container matColumnDef="Fresher">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-right">Fresher</th>
        <td mat-cell *matCellDef="let element" class="td-center"> {{element.IsFresher}} </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data</td>
         </tr>
    </table>
  </div>
  <div class="row justify-content-center">
    <mat-paginator [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
</div>
<ngx-spinner
 bdColor="rgba(0, 0, 0, 0.1)"
 color="#585858"
 [fullScreen]="false"
 type="ball-clip-rotate-multiple" 
 size="medium">
    <p class="loading">Loading ...</p>
</ngx-spinner>
</mat-card-content>
</mat-card>