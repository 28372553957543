export class CauseForm{
  
    employeeId: number;
    submitType:  string;
    associateExitId:  number;
    rootCause: string;
    actionItem: string;
    responsibility: string;
    tagretDate: string;
    actualDate: string;
    remarks: string;
    systemInfo: string;
    createdBy: string;
    modifiedBy:string;
  
  }