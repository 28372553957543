<h1 mat-dialog-title class="text-center">{{data.heading}}</h1>
<mat-dialog-content>
    <div class="row text-center">
        {{data.message}}
        </div>
    
        <br>
    
        <div class="row">
            <div class="col-12 text-right">
                <button type="submit" mat-flat-button color="primary" (click)="onNoClick()">Okay
              </button>
        </div>
        </div>
</mat-dialog-content>
