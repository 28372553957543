import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hra-dashboard',
  templateUrl: './hra-dashboard.component.html',
  styleUrls: ['./hra-dashboard.component.scss']
})
export class HraDashboardComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
