export class ReasonForm {
   
    public EmployeeCode: number;
    public EmployeeName: string;
    public ExitDate: string;
    public reason: string;
    public associatenumber: number;
    public ProgramManager: string;
    public FromDate: string;
    public ToDate: string;
  }
  

  export class reasonData  {
    public FromDate: Date;
    public ToDate: Date;
}