<div class="row">
        <div class="col-md-12">
                <h3 class="dividerHeading">Projects</h3>
        </div>
    </div>
    
     <form  *ngIf="domainList" [formGroup]="addproject" class="form-horizontal row justify-content-center">
       <div class="col-md-12">
            <div formArrayName="projectarray">
              <div class="table-responsive">
            <table mat-table [dataSource]="projects" cellpadding="4" >
                    <ng-container matColumnDef="Id">
                        <th mat-header-cell *matHeaderCellDef> <span class="mr-3">S.no</span> </th>
                        <td mat-cell *matCellDef="let element;let i = index; let lst = last; let fst=first"> {{i+1}} </td>
                   </ng-container> 
        
                   <ng-container matColumnDef="DomainId">
                     <th mat-header-cell *matHeaderCellDef> Domain Name <span class="text-danger">*</span></th>
                     <td mat-cell *matCellDef="let project;let i = index; let lst = last; let fst=first" [formGroupName]="i">
                        <mat-form-field appearance={{themeConfigInput}}>
                          <mat-select  formControlName="DomainID"  [value]="project.DomainId" (keypress)="omit_special_char($event)" 
                          (selectionChange)="this.projects[i].DomainId = project['DomainId'] = $event.value" >
                            <mat-option value = "null"></mat-option>
                            <mat-option *ngFor="let g of domainList" [value]=g.value>{{g.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="testArray.controls[i].get('DomainID').hasError('required')">Domain Name is Required</mat-error>
                    </mat-form-field> 
                    </td>
                   </ng-container>
        
                   <ng-container matColumnDef="OrganizationName">
                        <th mat-header-cell *matHeaderCellDef> Organisation Name  <span class="text-danger">*</span></th>
                        <td mat-cell *matCellDef="let project;let i = index; let lst = last; let fst=first" [formGroupName]="i">
                        <mat-form-field appearance={{themeConfigInput}}>
                             <input  matInput  type="text" formControlName="OrganizationName"
                            [value]="project.OrganizationName" (paste)="$event.preventDefault()" (keypress)="omit_special_char($event)"
                            (change)="this.projects[i].OrganizationName = project['OrganizationName'] = $event.target.value">
                            <mat-error *ngIf="testArray.controls[i].get('OrganizationName').hasError('required')">Organization Name is Required</mat-error>
                          </mat-form-field>
                        </td>
                   </ng-container>
                 
                   <ng-container matColumnDef="ProjectName">
                     <th mat-header-cell *matHeaderCellDef> Project Name  <span class="text-danger">*</span></th>
                    <td mat-cell *matCellDef="let project; let i = index; let lst = last; let fst=first" [formGroupName]="i"> 
                            <mat-form-field appearance={{themeConfigInput}} >
                                <input matInput type="text" formControlName="ProjectName" [value]="project.ProjectName"
                                (change)="this.projects[i].ProjectName = project['ProjectName'] = $event.target.value"
                                (paste)="$event.preventDefault()">
                                <mat-error *ngIf="testArray.controls[i].get('ProjectName').hasError('required')">Project Name is Required</mat-error>
                                <mat-error *ngIf="testArray.controls[i].get('ProjectName').hasError('maxlength')">Maximum 25 characters allowed</mat-error>
                                <mat-error *ngIf="testArray.controls[i].get('ProjectName').hasError('pattern')">Enter Valid Project Name</mat-error>

                            </mat-form-field>
                     </td>
                   </ng-container>
                 
                   <ng-container matColumnDef="Duration">
                     <th mat-header-cell *matHeaderCellDef> Duration(months)  <span class="text-danger">*</span></th>
                     <td mat-cell *matCellDef="let project;let i = index; let lst = last; let fst=first" [formGroupName]="i">
                            <mat-form-field appearance={{themeConfigInput}}>
                                <input matInput type="text" formControlName="duration"  [value]="project.Duration"
                                (paste)="$event.preventDefault()" (change)="this.projects[i].Duration = project['Duration'] = $event.target.value"
                                (keypress)="onlyNumbers($event)">
                                <mat-error *ngIf="testArray.controls[i].get('duration').hasError('required')">Duration is Required</mat-error>
                                <mat-error *ngIf="testArray.controls[i].get('duration').hasError('pattern')">Should be more than 0 and should not start with 0</mat-error>

                            </mat-form-field>
                     </td>
                   </ng-container>
        
                   <ng-container matColumnDef="KeyAchievements">
                        <th mat-header-cell *matHeaderCellDef> Key Achievements  <span class="text-danger">*</span></th>
                        <td mat-cell *matCellDef="let project;let i = index; let lst = last; let fst=first" [formGroupName]="i">
                         <mat-form-field appearance={{themeConfigInput}}>
                                <textarea matInput type="text" formControlName="keyAchievement" [value]="project.KeyAchievements"
                                (paste)="$event.preventDefault()"
                                (change)="this.projects[i].KeyAchievements = project['KeyAchievements'] = $event.target.value"></textarea>
                                <mat-error *ngIf="testArray.controls[i].get('keyAchievement').hasError('required')">Key achievements is Required</mat-error>
                                <mat-error *ngIf="testArray.controls[i].get('keyAchievement').hasError('pattern')">Enter Valid Key achievements</mat-error>

                         </mat-form-field>    
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="Actions">
                            <th mat-header-cell *matHeaderCellDef> Actions </th>
                            <td mat-cell *matCellDef="let element; let i = index; let lst = last; let fst=first" >
                                <button mat-icon-button type="button" (click)="onNewProject()" [hidden]="!lst">
                                <mat-icon >add</mat-icon></button> </td>
                     </ng-container>
                     <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let i = index; let lst = last; let fst=first"> 
                                <button mat-icon-button type="button" (click)="Delete(i)"[hidden]="fst&&lst">
                                <mat-icon>delete</mat-icon></button> </td>
                     </ng-container>
        
                     
        
                 
                   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                   
                 </table> 
            </div>
                 <div class="row">
                        <div class="col-lg-12">
                             <br><br> 
                            <section class="pull-right buttons">
                                <button type="submits"  mat-flat-button color="primary"  *ngIf="type == 'new'"  (click)="OnSubmit(projects)">
                                    Save
                                </button>
                              <button type="submits"  mat-flat-button color="primary" *ngIf="type != 'new'"   (click)="OnSubmit(projects)">
                                   Update
                               </button>
                             </section>
                        </div>  
                </div>
                </div>
              </div>
     </form>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" color="#585858" [fullScreen]="true" type="ball-clip-rotate-multiple"
      size="medium">
      <p class="loading">Loading ...</p>
    </ngx-spinner>
    
  