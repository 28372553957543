import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hrm-dashboard',
  templateUrl: './hrm-dashboard.component.html',
  styleUrls: ['./hrm-dashboard.component.scss']
})
export class HrmDashboardComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    
  }

}
