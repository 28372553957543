<mat-card class="mt-2">
       <form [formGroup]="myForm">   
        <div class="row">
            <div class="col-md-4">
        
        
                <mat-form-field appearance="{{ themeAppearence }}">
                    <mat-label>{{selectedColumns.length}} columns selected</mat-label>
                    <mat-select [formControl]="myForm.controls.columnselect" placeholder="Select Options" multiple name="optionsSelector" (selectionChange)="alterTable($event)">
                        <mat-option>
                            <ngx-mat-select-search formControlName="columnfilter" placeholderLabel="Search" noEntriesFoundLabel="No Record Found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
                        <mat-option *ngFor="let q of filteredBanksMulti | async" [value]="q" (click)="tosslePerOne(allSelected.viewValue)">
                            {{q.header}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <div class="mt-3">
                    <b>Total Records:</b>
                    <span>{{totalRecordsCount >0 ? totalRecordsCount: 0}}</span>
                </div>
            </div>
        </div>
        </form>
<div *ngIf="pageLoad">

       
            <a *ngIf="projectDetails.length>0 "
                (click)="exporterIntoExcel()" (click)="export()" title="Export to Excel">
                <div class="exportToExcel"></div>
            </a>
        
            <div class="table-responsive">
                    <table mat-table #table matTableExporter [dataSource]="projectsInfo"  matSort #exporter="matTableExporter" id = "ExampleMaterialTable">
                        <ng-container *ngFor="let col of selectedColumns" matColumnDef="{{col.header}}">
                            <th  mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">{{col.header}}
                            </th>
                            <td mat-cell *matCellDef="let element">{{element[col.field]}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displaycols"></tr>
    
                        <tr mat-row *matRowDef="let row; columns: displaycols;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data</td>
    
                    </table>
                </div>
       
        <div class="row justify-content-center">
            <mat-paginator  [pageSizeOptions]="[15, 30, 45]"></mat-paginator>
        </div>
    
</div>
</mat-card>