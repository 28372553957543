<mat-card class="mb-2">
  <mat-card-content>
    <form [formGroup]="addNotificationType" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-6">

          <mat-form-field appearance={{themeConfigInput}}>
            <mat-label>Category
              <span class="text-danger">*</span>
            </mat-label>
            <mat-select placeholder="Select Category" formControlName="CategoryMasterId">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="search($event.target.value)" autocomplete="off" placeholder="search" appValidations appEmptySpacesValidations
                minlength="2">
              <mat-option disabled>Select Category</mat-option>
              <mat-option *ngFor="let category of categoriesList" [value]="category.value">
                {{category.label}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="addNotificationType.controls['CategoryMasterId'].errors">
              <i style="color: red" class="fa fa-exclamation-circle"></i>
              <span style="color: red">Category Required.</span>
            </mat-error>
          </mat-form-field>

        </div>
        <div class="col-md-6">
          <mat-form-field appearance={{themeConfigInput}}>
            <mat-label>Notification Type
              <span class="text-danger">*</span>
            </mat-label>
            <input matInput type="text" appValidationImpSpecialChars appEmptySpacesValidations maxlength="50"
              title="No special characters are allowed in Notification Type" (paste)="$event.preventDefault()"
              formControlName="NotificationCode">
            <mat-error *ngIf="addNotificationType.controls['NotificationCode'].errors">
              <span style="color: red">Notification Type is Required</span>
            </mat-error>
          </mat-form-field>

        </div>
        <div class="col-md-6">
          <mat-form-field appearance={{themeConfigInput}}>
            <mat-label>Notification Type Description<span class="text-danger"> *</span></mat-label>
            <input matInput type="text" maxlength="150" (paste)="$event.preventDefault()"
              formControlName="NotificationDescription" minlength="2" appEmptySpacesValidations>
            <mat-error *ngIf="addNotificationType.controls['NotificationDescription'].errors">
              <span style="color: red">Notification Description is Required</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12 text-right">
          <button (click)="reset()" type="reset" mat-stroked-button>
            <mat-icon>clear
            </mat-icon>Clear
          </button>
          <button type="submit" mat-flat-button color="primary">
            {{btnLabel}}</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="CategoryName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Category Name</th>
          <td mat-cell *matCellDef="let element"> {{element.CategoryName}} </td>
        </ng-container>
        <ng-container matColumnDef="NotificationCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Notification Type</th>
          <td mat-cell *matCellDef="let element"> {{element.NotificationCode}} </td>
        </ng-container>
        <ng-container matColumnDef="NotificationDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Notification Type Description
          </th>
          <td mat-cell *matCellDef="let element"> {{element.NotificationDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editnotificationtype(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>

      </table>

    </div>
    <div class="row justify-content-center">
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>