<h1 mat-dialog-title *ngIf="this.submitType==='Revoke'">Revoke Resignation</h1>
<h1 mat-dialog-title *ngIf="this.submitType==='Approve'">Accept Revoke</h1>
<h1 mat-dialog-title *ngIf="this.submitType==='Reject'">Reject Revoke</h1>
    <mat-dialog-content>
    <form [formGroup]="revokeResignation">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance={{themeConfigInput}}>
                    <mat-label  *ngIf="this.submitType==='Revoke'">Reason for revoking<span class="text-danger">*</span></mat-label>
                    <mat-label  *ngIf="this.submitType==='Approve'">Reason for accepting revoke<span class="text-danger">*</span></mat-label>
                    <mat-label  *ngIf="this.submitType==='Reject'">Reason for rejecting revoke<span class="text-danger">*</span></mat-label>
                    <textarea matInput formControlName="RevokeReason" name="RevokeReason"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-right">
              <button mat-stroked-button (click)="onNoClick()">
                <mat-icon>close</mat-icon>Cancel
              </button>
              <button type="submit" mat-flat-button color="primary" (click)="save()">Submit</button>
    
            </div>
          </div>
    </form>
    </mat-dialog-content>
