<!-- <h4>Attendance is marked by Bio metric</h4> -->
<h2 mat-dialog-title>Alert
</h2>

<mat-dialog-content class="content-css">
    <mat-icon class="warning-css">error_outline</mat-icon>&nbsp;<span>Attendance is already marked</span>
</mat-dialog-content>

<mat-dialog-actions style="float: right">
    <button mat-flat-button mat-dialog-close color = "primary">Close</button>
  </mat-dialog-actions>
