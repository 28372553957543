// Types of form field appearances are:
// 1. legacy
// 2. standard
// 3. fill
// 4. outline

export const themeconfig = {
  formfieldappearances: 'outline'
};
 
