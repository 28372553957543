<mat-card class="mb-2">
    <mat-card-content>
        <form [formGroup]="allocationForm">
            <div class="row">
              
                <div class="col-md-4">
            <mat-form-field appearance={{themeConfigInput}} class="customMatSelect">
                    <mat-label>Associate Name<span class="text-danger"> *</span></mat-label>
                    <input appDropDownSuggestion [SearchList]="associatesList"
                    appRequirematch 
                    placeholder="Select Associate" type="text" matInput 
                    formControlName="EmployeeId" #trigger="matAutocompleteTrigger"
                    [matAutocomplete]="auto" appValidations appEmptySpacesValidations minlength="2"/>
                    <button class="clearFieldIcon" *ngIf="allocationForm.value.EmployeeId" mat-button matSuffix
                        mat-icon-button aria-label="Clear" (click)="clearField()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-autocomplete  #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="getProjectsByEmployeeId(allocationForm.value.EmployeeId.value)">
                        <mat-option *ngIf="allocationForm.get('EmployeeId').hasError('invalidValue') && allocationForm.value.EmployeeId"
                        disabled>No matching value found</mat-option>
                        <mat-option *ngFor="let option of filteredOptionsName | async" [value]="option" >
                        {{ option.label }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="allocationForm.get('EmployeeId').hasError('requireMatch')">
                        Select Associate from given options
                    </mat-error>
                    <mat-error *ngIf="allocationForm.get('EmployeeId').hasError('required') && formsubmitted">
                        Select Associate
                    </mat-error>
                    </mat-form-field>
                    <!-- <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>Associate Name
                            <span class="text-danger">*</span>
                        </mat-label>

                        <mat-select  formControlName="EmployeeId" name="EmployeeId"
                            (selectionChange)=getProjectsByEmployeeId($event.value)>
                            <mat-option *ngFor="let user of associatesList" [value]="user.value">
                                {{user.label}}</mat-option>
                        </mat-select>

                        <mat-error *ngIf="!allocationForm.controls['EmployeeId'].valid && formsubmitted">
                            <i style="color: red" class="fa fa-exclamation-circle"></i>
                            <span style="color: red">Select Associate Name</span>
                        </mat-error>
                    </mat-form-field> -->
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>Project Name
                            <span class="text-danger">*</span>
                        </mat-label>
                        <mat-select placeholder="Select Project" formControlName="ProjectId" [disabled]="disableddlProject" [(ngModel)]="requisitionList.ProjectId"
                            (selectionChange)="GetCurrentAllocation(allocationForm.value.EmployeeId.value,allocationForm.value.ProjectId); ">
                            <mat-option *ngFor="let project of projectsList" [value]="project.value">
                                {{project.label}}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="(!allocationForm.controls['ProjectId'].valid && formsubmitted)">
                            <i style="color: red" class="fa fa-exclamation-circle"></i>
                            <span style="color: red">Select Project Name</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>Effective Date:
                            <span class="text-danger">*</span>
                        </mat-label>
                        <input formControlName="EffectiveDate" matInput [matDatepicker]="picker" [min]="minDate" [(ngModel)]="requisitionList.EffectiveDate"
                            [max]="maxDate" readonly (click)="picker.open()" style="cursor: pointer">
                        <mat-datepicker-toggle matSuffix *ngIf="allocationForm.value.EffectiveDate"
                            (click)="clearInput($event, 'EffectiveDate')">
                            <mat-icon matDatepickerToggleIcon>close</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="!allocationForm.controls['EffectiveDate'].valid && formsubmitted">
                            <span style="color: red"> Enter Effective Date</span>
                        </mat-error>
                    </mat-form-field>
                </div>
              
                <div class="col-md-4">
                    <mat-checkbox  formControlName="isCritical" (change)="checkValue($event)">Critical</mat-checkbox>
                </div>

                <div class="col-md-12 text-right">
                    <button  (click)="Cancel()" mat-stroked-button>
                        <mat-icon>clear
                        </mat-icon>Clear
                    </button>
                    <button type="submit" (click)="UpdateAssociateAllocation(allocationForm.value)" mat-flat-button color="primary">
                        Update</button>
                </div>

            </div>
        </form>

    </mat-card-content>
</mat-card>

<mat-card class="col-md-12 floating-main" *ngIf="showAllocationHistoryGrid">

    <h5>
        <b>{{requisitionList.AssociateName}}'s</b> - Current Allocation</h5>
    <div class="col-lg-12">
        <table class="table table-bordered">
            <!-- <table class="table table-nowraptext"> -->
            <tr class="d-center">
                <th>
                    <span class="text">Project Name</span>
                </th>
                <th>
                    <span class="text">Effective From</span>
                </th>
                <th>
                    <span class="text">Utilization (%)</span>
                </th>
                <th>
                    <span class="text">Billable</span>
                </th>
                <th>
                    <span class="text">Critical</span>
                </th>
                <th>
                    <span class="text">Primary</span>
                </th>
            </tr>
            <tbody>
                <tr class="text-center data-rows">
                    <td class="text-left">{{allocationHistory.Project}}</td>
                    <td class="text-left">{{allocationHistory.EffectiveDate | date:"MM/dd/yyyy"}}</td>
                    <td class="text-left">{{allocationHistory.AllocationPercentage}}</td>
                    <td class="text-left">
                        <input name="HRABillable" type="checkbox" [checked]="allocationHistory.Billable" disabled>
                    </td>
                    <td class="text-left">
                        <input name="HRABillable" type="checkbox" [checked]="allocationHistory.isCritical" disabled>
                    </td>
                    <td class="text-left">
                        <input name="HRABillable" type="checkbox" [checked]="allocationHistory.IsPrimary" disabled>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</mat-card>
