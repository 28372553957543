<mat-card>
    <mat-card-content>
        <div class="row">
            <div class="col-md-12">               
                <div class="table-responsive">
                    <table #table  mat-table [dataSource]="dataSource" #sort1="matSort" matSort class="mat-elevation-z8" >
                     
                      <ng-container matColumnDef="FinancialYearName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-left">Financial Year</th>
                        <td mat-cell *matCellDef="let element" class="td-left"> {{element.FinancialYearName}} </td>
                      </ng-container>
                
                    <ng-container matColumnDef="RoleTypeName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="font-weight-bold th-left">Role Type</th>
                        <td mat-cell *matCellDef="let element" class="td-left"> {{element.RoleTypeName}} </td>
                      </ng-container> 
                      <ng-container matColumnDef="DownloadKRA">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Download KRA </th>
                        <td mat-cell *matCellDef="let element">
                          <!-- <mat-icon style="cursor: pointer;" class="delete" *ngIf="editKRA" (click)="onDeleteKRA(element.DefinitionDetailsId)">delete</mat-icon> -->
                          <button *ngIf="element.DownloadKRA" mat-flat-button color="primary" (click)="DownloadKRA(element)">  <mat-icon style="cursor: pointer" >download</mat-icon> Download KRA </button>
                        </td>
                      </ng-container>       
                       
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data</td>
                         </tr>
                    </table>
                  </div>
                <div class="row justify-content-center">
                    <mat-paginator [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" color="#585858" [fullScreen]="false" type="ball-clip-rotate-multiple"
    size="medium">
    <p class="loading">Loading ...</p>
</ngx-spinner>
