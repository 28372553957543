<mat-card class="mb-2">
  <mat-card-content>
    <form [formGroup]="addDesignation">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="{{ themeappeareance }}">
            <mat-label>Grade Code<span class="text-danger"> *</span></mat-label>
            <input [SearchList]="GradeCodeMasterData"  
            appRequirematch appEmptySpacesValidations type="text" 
            matInput formControlName="GradeId" [matAutocomplete]="auto" 
            placeholder="Grade Code"/>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptionsGrade | async" [value]="option"
                (optionSelected)="onGradeChange($event, gradeData.GradeId)">
                {{ option.label }}
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="addDesignation.get('GradeId').hasError('required')">
              Grade Code Required
            </mat-error>
            <mat-error *ngIf="addDesignation.get('GradeId').hasError('requireMatch')">
              Select Grade from given options
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="{{ themeappeareance }}">
            <mat-label>Designation Code<span class="text-danger"> *</span></mat-label>
            <input matInput placeholder="Designation Code" formControlName="DesignationCode" appValidations appEmptySpacesValidations
              minlength="2" />
            <mat-error *ngIf="addDesignation.get('DesignationCode').errors">
              Designation Code Required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="{{ themeappeareance }}">
            <mat-label>Designation Name<span class="text-danger"> *</span></mat-label>
            <input matInput placeholder="Designation Name" formControlName="DesignationName" appValidationImpSpecialChars appEmptySpacesValidations
              minlength="2" />
            <mat-error *ngIf="addDesignation.get('DesignationName').errors">
              Designation Name Required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6 text-right">
          <button (click)="Reset()" type="button" mat-stroked-button>
            <mat-icon>clear </mat-icon>Clear
          </button>
          <button type="button" mat-flat-button color="primary" (click)="CreateDesignation()">
            {{ btnLabel }}
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <table class="mat-elevation-z8 col-12" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="GradeCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">
            Grade Code
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.GradeCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="DesignationCode">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header class="font-weight-bold">
            Designation Code
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.DesignationCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="DesignationName">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header class="font-weight-bold">
            Designation Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.DesignationName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef class="font-weight-bold">
            Edit
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="cursor: pointer" (click)="setEditObj(element)">edit</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="row justify-content-center">
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>