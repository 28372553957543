<mat-sidenav-container>
      <mat-sidenav #sidenav position="end">
        <form [formGroup]="commentForm">
        <mat-card class="no-shadow">
        <h4 class="commentTitle">Comments <span class="pull-right" ><span class="filter">All  <img src="../../../../assets/images/downarrow.svg" ></span><img src="../../../../assets/images/settings.svg"/>       <button (click)="sidenav.toggle()" mat-icon-button  aria-label="Example icon button with a home icon">
           <mat-icon>close</mat-icon>
         </button> </span></h4>
      </mat-card>
      
      <mat-card class="no-shadow commentCard"> 
      <!-- <h6 class="commentTitle">Today</h6> -->
      <div class="commentMxH">
      
      <!-- <div class="commentDiv"> -->
      <!-- <p class="cmntHeading"><img src="../../../../assets/images/hrms/icons/commentsmall.svg"/> 2</p>
      <p class="cmntHeading">Upadhyay, Deshpande 1 hours ago</p> -->
      <!-- <p  class="cmntDesc"><b>{{commentText}}</b></p> -->
      
      <!-- <p class="cmntHeading">7 reply</p> -->
      
      <!-- <div class="commentDiv">
      <p class="cmntHeading"><img src="../../../../assets/images/hrms/icons/commentsmall.svg"/> 2</p>
      <p class="cmntHeading">Upadhyay, Deshpande 1 hours ago</p>
      <p  class="cmntDesc"><b>Please have a look at this particular KRA, I feel we could add another one</b></p>
      <p class="cmntHeading">7 reply</p>
      </div> -->
      <div>
      <mat-label class="cmntDesc">{{commentText}}</mat-label>
      </div>
      </div>
      
      <div class="row">
      <div class="col-md-11">
        <mat-form-field appearance="legacy">
           <mat-label>Type a new comment</mat-label>
           <input matInput formControlName="comment">
         </mat-form-field>
      </div>
      <div class="col-md-1">
        <button  color="primary" (click)="saveComment()" mat-icon-button aria-label="Example icon button with a home icon">
           <mat-icon>send</mat-icon>
         </button> 
      </div>
      </div>
      
      </mat-card>
      </form>
      </mat-sidenav>

<mat-card class="mb-2">
      <mat-card-content>
            <form [formGroup]="ceoStatusForm">
                  <div class="row">
                        <div class="col-md-6">
                              <mat-form-field appearance="{{themeappeareance}}">
                                    <mat-label>Financial Year</mat-label>
                                    <mat-select placeholder="Select Financial Year" formControlName="FinancialYearId" (selectionChange)="onFinancialYearChange($event)"
                                          required>
                                          <mat-option *ngFor="let fin of financialYearList" [value]="fin.value">
                                                {{fin.label}}
                                          </mat-option>
                                    </mat-select>
                              </mat-form-field>
                        </div>
                        <div *ngIf="approveByCEO" class="ApproveButton col-md-2">
                              <button type="submit" (click)="approvedByCEO()" mat-flat-button color="primary">Approve</button>
                        </div>
                  </div>
            </form>
      </mat-card-content>
</mat-card>

<mat-card matSort>
      <mat-card-content>
            <div class="table-responsive">
                  <table class="mat-elevation-z8 col-12" mat-table [dataSource]="dataSource" cdkDropList cdkDropListOrientation="horizontal"
                        (cdkDropListDropped)="drop($event)" matSort>
                        <ng-container matColumnDef="departmentname">
                              <th mat-header-cell *matHeaderCellDef> DEPARTMENT NAME </th>
                              <td mat-cell *matCellDef="let element"> <b>{{element.DepartmentName}}</b> <br />
                                    <!-- <i class="text-muted"><small>{{element.departmentHeadName}}</small></i></td> -->
                        </ng-container>
                                    <ng-container matColumnDef="noofroletypes">
                        <th class="w30p" mat-header-cell *matHeaderCellDef> NO OF ROLE TYPES <br/><span class="versmallfont"><span class="text-muted">TOTAL |</span> <span class="text-success">ACCEPTED |</span> <span class="text-danger">FOR REVIEW</span></span></th>
                        <td class="w25p" mat-cell *matCellDef="let element">
                              <p class="wspan"><span>{{element.TotalRoleTypeCount}}</span> 
                                    <span>
                                    {{element.AcceptedRoleTypesCount}}</span> 
                                    <span>{{element.UnderReviewRoleTypesCount}}
                                    </span> 
                              
                        </p>  </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef> STATUS </th>
                              <td mat-cell *matCellDef="let element" [ngClass]="{'greenClr': element.Status=='Completed'}"> {{element.Status}} 
                                    <!--<span class="text-muted">-->
                        <!--<small *ngIf="element.Status !=='KRA received'">N A</small></span>-->
                              </td>
                        </ng-container>
     <!-- <ng-container matColumnDef="comments">
                  <th mat-header-cell *matHeaderCellDef> COMMENTS </th>
                  <td mat-cell *matCellDef="let element"> {{element.comments}} 
                  <div>
                        <button mat-icon-button aria-label="Comment">
                              <img class="iconimg" (click)="openComments(element.DepartmentId)" src="../../../../../assets/icons/comment.svg" alt="Comment">
                              </button>
                       
                     </div>
                  </td>
               </ng-container> -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
            </div>
      </mat-card-content>
</mat-card>
</mat-sidenav-container>