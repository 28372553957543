<mat-card>
    <div class="row">
        <div class="col-md-6 pr-md-0">
            <mat-form-field appearance={{themeConfigInput}}>
                <mat-label>Department
                    <span class="text-danger">*</span>
                </mat-label>
                <mat-select (selectionChange)="onDepartmentChange($event)" [formControl]="departmentId">
                    <mat-option *ngFor="let item of nonDeliveryDeparmentsList"
                        [value]="item.DepartmentId">{{item.Description}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="departmentId.hasError('required')">Department is
                    Required</mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-3">
            <button mat-flat-button color="primary" class="assignRM" (click)="assignRM()"
                [disabled]="selection.selected.length == 0">Assign RM</button>
        </div>
    </div>
</mat-card>

<mat-card class="mt-2">
    <div>
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>&nbsp;All
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="EmpName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.EmpName}} </td>
            </ng-container>

            <ng-container matColumnDef="ReportingManager">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Manager </th>
                <td mat-cell *matCellDef="let element"> {{element.ReportingManager}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
            <tr class="mat-row " *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
        </table>
        <div class="justify-content-center">
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</mat-card>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" color="#585858" [fullScreen]="false" type="ball-clip-rotate-multiple"
    size="medium">
    <p class="loading">Loading ...</p>
</ngx-spinner>