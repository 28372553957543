<mat-card>
    <mat-card-content>
        </mat-card-content>
        <div>
         
        <form  [formGroup]="causeForm">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>From Date</mat-label>
                        <input formControlName="EffectiveDate" matInput [matDatepicker]="from" >
                        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                        <mat-datepicker #from></mat-datepicker>
                   
                    </mat-form-field>


                    <!-- <mat-form-field appearance={{themeAppearence}}>
                        <mat-label>From Date<span style="color: red"> *</span></mat-label>
                        <input matInput [matDatepicker]="from" (click)="from.open()" formControlName="fromDate" format='dd/mm/yy' (dateChange)="searchData.FromDate = $event.value"
                            [value]="searchData.FromDate" (paste)="$event.preventDefault()">
                        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                        <mat-datepicker #from></mat-datepicker>
                        <mat-error *ngIf="myForm.controls.fromDate.hasError('required')">From Date is required</mat-error>
                    </mat-form-field> -->

                </div>
                <div class="col-md-4">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>From Date</mat-label>
                        <input formControlName="EffectiveDate" matInput [matDatepicker]="to">
                        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                        <mat-datepicker #to></mat-datepicker>
                   
                    </mat-form-field>
                </div>
             
            </div>
            <div class="col-md-12">
                <section class="pull-right buttons">
                    <button type="button" mat-flat-button (click)="clearFilter()">
                        Clear</button>
                    <button type="submit" mat-flat-button color="primary" (click)="apply()">
                        Apply</button>
                </section>
            
            </div>
            </form>
        </div>
        <br>
</mat-card>
<mat-card>
    <div>
        <form [formGroup]="causeForm">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="employeeId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Id </th>
                    <td mat-cell *matCellDef="let element">{{element.EmployeeCode}} </td>
                  </ng-container>
          
                  <ng-container matColumnDef="employeeName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Name </th>
                    <td mat-cell *matCellDef="let element">{{element.EmployeeName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="exitDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Exit Date </th>
                    <td mat-cell *matCellDef="let element">{{element.ExitDate}} </td>
                  </ng-container>
          
                  <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Reason </th>
                    <td mat-cell *matCellDef="let element">{{element.reason}} </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="associatenumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Number of Associates </th>
                    <td mat-cell *matCellDef="let element">{{element.associatenumber}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data</td>
                  </tr>
                </table>
              </div>
              <div class="row justify-content-center">
                <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
              </div>
        </form>
    </div>
</mat-card>
