
<h1 mat-dialog-title >Skill Details </h1>

<div mat-dialog-content> 
<table mat-table [dataSource]="skillsData" class="mat-elevation-z8">
    <ng-container matColumnDef="SkillName">
        <th mat-header-cell *matHeaderCellDef  class="font-weight-bold">SkillName</th>
      <td mat-cell *matCellDef="let element"> {{element.SkillName}} </td>
  
    </ng-container>

    <ng-container matColumnDef="ProficiencyLevelCode">
      <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> ProficiencyLevelCode </th>
      <td mat-cell *matCellDef="let element"> {{element.ProficiencyLevelCode }} </td>
    </ng-container>

    <ng-container matColumnDef="SkillExperience">
        <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> SkillExperience </th>
        <td mat-cell *matCellDef="let element"> {{element.SkillExperience }} </td>
      </ng-container>
      <ng-container matColumnDef="LastUsed">
        <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> LastUsed </th>
        <td mat-cell *matCellDef="let element"> {{element.LastUsed }} </td>
      </ng-container>
   
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr> -->
  </table>
</div>
<div class="row justify-content-center">
  <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
</div>
<div class="col-md-12 text-right">
  <button mat-button (click)="onNoClick()"mat-stroked-button>Close</button>
 
</div>