<mat-card class="mb-2">
    <mat-card-content>
        <form [formGroup]="abscondForm">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label> Employee <span class="text-danger">*</span></mat-label>
                        <input appDropDownSuggestion type="text" placeholder="Select Associate" matInput
                            [SearchList]="associatesList" appRequirematch formControlName="associateId"
                            name="associateId" [matAutocomplete]="auto1">
                        <button class="clearFieldIcon" *ngIf="abscondForm.value.associateId"
                            (click)="clearField('associateId', $event)" mat-button matSuffix mat-icon-button aria-label="Clear">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-autocomplete (optionSelected)='GetSelectedAssociate($event.option.value.value)' [displayWith]="displayFn" #auto1="matAutocomplete">
                            <mat-option *ngFor="let item of filteredAssociates | async;" [value]="item">
                                {{item.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="abscondForm.get('associateId').hasError('required') ">
                            Employee is required
                        </mat-error>
                        <mat-error *ngIf="abscondForm.get('associateId').hasError('requireMatch')">
                            Select Employee from given options
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>Absent From Date<span class="text-danger">*</span></mat-label>
                        <input matInput [matDatepicker]="absentFromDate" [max]="currentDate"
                            formControlName="absentFromDate" name="absentFromDate">
                        <mat-datepicker-toggle matSuffix [for]="absentFromDate"></mat-datepicker-toggle>
                        <mat-datepicker #absentFromDate></mat-datepicker>
                        <mat-error *ngIf="abscondForm.get('absentFromDate').hasError('required')">
                            Absent From Date is required.
                        </mat-error>
                    </mat-form-field>

                </div>
                <div class="col-md-3">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>Absent To Date<span class="text-danger">*</span></mat-label>
                        <input matInput [matDatepicker]="absentToDate" [min]="abscondForm.value.absentFromDate"
                            [max]="currentDate" formControlName="absentToDate" name="absentToDate">
                        <mat-datepicker-toggle matSuffix [for]="absentToDate"></mat-datepicker-toggle>
                        <mat-datepicker #absentToDate></mat-datepicker>
                        <mat-error *ngIf="abscondForm.get('absentToDate').hasError('required')">
                            Absent To Date is required.
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>Remarks By Team Lead<span class="text-danger">*</span></mat-label>
                        <textarea matInput formControlName="remarksByTL" name="remarksByTL"></textarea>
                        <mat-error *ngIf="abscondForm.get('remarksByTL').hasError('required')">
                            Remarks is Required.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-md-12 text-right">
                <button mat-stroked-button (click)="clear()">
                    <mat-icon>clear</mat-icon> Clear</button>
                <button type="submit" class="mr-0" mat-flat-button color="primary" (click)="CreateAbscondRequest()">Submit</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>