
export class CommentModel {
    CommentID: number;
    CommentText: string;
    FinancialYearId: number;
    DepartmentId: number;
    ApplicableRoleTypeId: number;
    RoleTypeId: number;
    GradeId: number;
    Username: string;
    IsCEO: boolean;
    CreatedBy: string;
}