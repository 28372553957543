export class MenuRoles {
    RoleId: number;
    RoleName?: string;
    MenuList: Menus[];

}

export class Menus {
    MenuId: number;
    MenuName?: string;
}
