export class ServiceType{
    ServiceTypeId: number
    ServiceTypeName: string
    ServiceDescription: string
    CurrentUser: string
    CreatedDate: Date
    ModifiedDate: Date
    SystemInfo: string
    IsActive: boolean
    CreatedBy: string
    ModifiedBy: string
}

// "ServiceTypeId": 1,
//     "ServiceTypeName": "IBMi",
//     "ServiceDescription": "IBMi Application services",
//     "CurrentUser": null,
//     "CreatedDate": "2021-02-23T12:11:15.260246+05:30",
//     "ModifiedDate": "2021-02-23T12:11:15.260246+05:30",
//     "SystemInfo": null,
//     "IsActive": true,
//     "CreatedBy": "Kalyan.Penumutchu@senecaglobal",
//     "ModifiedBy": "Anonymous"