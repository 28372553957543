
export class ResourceRelease {
    public employeeId: number;
    public ProjectId: number;
    public ProjectName: string;
    public projectTypeCode: string;
    public releaseProjectId: number;
    public reportingManagerId: number;
    public talentpool: string;
    public EffectiveDate: string
    public releaseDate: string;
    public ReleasingPercentage: number;
    public AllocationPercentage: number;
    public ProjectType: number;
    public TalentRequisitionId: number;
    public NotifyAll: boolean = false;
    public PrimaryProjectId:number;
    public MakePrimaryProjectId : number;
    public IsPrimary : boolean;
    public remainingProjects: number[];
}

