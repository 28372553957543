<h2 mat-dialog-title>Select Associate Project</h2>
<mat-dialog-content class="mat-typography">
    <div *ngFor="let p of projects">
        <!-- <b> {{fruit.projectName}} </b>   -->
        <button mat-button mat-dialog-close (click)="goToKtPlan(p)">{{p.projectName}}</button>         
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close >Cancel</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>