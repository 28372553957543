<mat-card class="mt-2">
    <mat-card-header>
        <mat-card-title>Regularization(s)</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table mat-table [dataSource]="_dataSource" matSort>

            <ng-container matColumnDef="empcode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Employee Code
              </th>
              <td mat-cell *matCellDef="let element;let i = index">
                {{ element.SubmittedBy }}
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name
              </th>
              <td mat-cell *matCellDef="let element;let i = index">
                {{ element.AssociateName }}
              </td>
            </ng-container>
            
            <ng-container matColumnDef="project">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Project
              </th>
              <td mat-cell *matCellDef="let element;let i = index">
                {{ element.ProjectName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Days">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Days
              </th>
              <td mat-cell *matCellDef="let element;let i = index">
                {{ element.RegularizationCount }}
              </td>
            </ng-container>
            <ng-container matColumnDef="View">
              <th mat-header-cell *matHeaderCellDef class="font-weight-bold">
                View
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon style="cursor: pointer" (click)="ViewAppliedRegularizationDays(element)">visibility</mat-icon>
              </td>
            </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No Record is available</td>
          </tr>
        </table>
        <div class="row justify-content-center">
          <mat-paginator #regularizationsPaginator [pageSizeOptions]="[10, 20, 30]"
                        showFirstLastButtons >
          </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>