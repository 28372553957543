<app-resignation [flag]="updateLWD" (lastWorkingDateEvent)=LastWorkingDateChange($event)></app-resignation>
<mat-card class="mb-2">
    <form [formGroup] ="PMApprovalform">
        <ng-container *ngIf="showClientImpact">
            <div class="row">
                <div class="col-md-3">
                    <mat-label>Client Impact<span class="text-danger">*</span></mat-label>
                </div>
                <div class="col-md-3">
                    <mat-radio-group formControlName="impactOnClientDelivery" color="primary">
                        <mat-radio-button [value]=true>Yes</mat-radio-button>
                        <mat-radio-button [value]=false>No</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-md-3">
                    <mat-label id="example-radio-group-label">Eligible for Rehire<span class="text-danger">*</span>
                    </mat-label>
                </div>
                <div class="col-md-3">
                    <mat-radio-group formControlName="rehireEligibility" color="primary">
                        <mat-radio-button [value]=true>Yes</mat-radio-button>
                        <mat-radio-button [value]=false>No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>Client Impact Details</mat-label>
                        <textarea placeholder="Client Impact Details" matInput
                        formControlName="impactOnClientDeliveryDetail" name="impactOnClientDeliveryDetail"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance={{themeConfigInput}}>
                        <mat-label>Reason for Rehire</mat-label>
                        <textarea placeholder="Rehire Reason" matInput
                        formControlName="rehireEligibilityDetail" name="rehireEligibilityDetail"></textarea>
                    </mat-form-field>
                </div>
    
            </div>
            
        </ng-container>
        <!-- <div class="row">
            <div class="col-md-6">
            <mat-form-field appearance={{themeConfigInput}}>
                <mat-label>Resignation Recommendation</mat-label>
                <textarea placeholder="Resignation Recommendation" formControlName="resignationRecommendation"matInput></textarea>
                <mat-hint>Required Field<span class="text-danger">*</span> : For Request To Withdraw Resignation</mat-hint>
            </mat-form-field>
            </div>
        </div> -->
        <div class="row">
        <div class="col-12 text-right">
        <!-- <button mat-stroked-button [disabled]="resignationApproved" (click)="withdrawRequest()">
            Request to Withdraw Resignation
        </button> -->
        <button [disabled]="AssociateExit && (AssociateExit.Status == 'RevokeRequested')" mat-flat-button color="primary" (click)="approveResignation()">{{btnLabel}}</button>

        </div>
        </div>
</form>
</mat-card>

