

<mat-card>
    <mat-card-content>
      <div class="table-responsive">
        <table mat-table [dataSource]="dataSourceAssociateExit" matSort>
            <ng-container matColumnDef="EmpCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Id </th>
                <td mat-cell *matCellDef="let AssocExit"> {{AssocExit.EmployeeCode}} </td>
              </ng-container>

              <ng-container matColumnDef="EmpName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Name </th>
                <td mat-cell *matCellDef="let AssocExit"> {{AssocExit.EmployeeName}} </td>
              </ng-container>

              <ng-container matColumnDef="ExitDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Exit Date </th>
                <td mat-cell *matCellDef="let AssocExit"> {{AssocExit.ExitDate}} </td>
              </ng-container>

              <ng-container matColumnDef="ExFeedInt">
                <th mat-header-cell *matHeaderCellDef> Exit Feedback Interview </th>
                <td mat-cell *matCellDef="let AssocExit">
                  <mat-icon (click)="onFeedback(AssocExit)" class="pointer">visibility</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="ExClr">
                <th mat-header-cell *matHeaderCellDef> Exit Clearance </th>
                <td mat-cell *matCellDef="let AssocExit">
                  <mat-icon (click)="onClearence(AssocExit)" class="pointer"> visibility</mat-icon>
                </td>
              </ng-container>
              

              <ng-container matColumnDef="Kt">
                <th mat-header-cell *matHeaderCellDef> Knowledge Transfer </th>
                <td mat-cell *matCellDef="let AssocExit">
                    <!-- <mat-icon (click)="onKnowledge(AssocExit)" class="v-align-middle">visibility</mat-icon> -->
                    <mat-icon (click)="openDialog(AssocExit)" class="v-align-middle" class="pointer">visibility</mat-icon>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
                </tr>

        
        </table>
      </div>
      <div class="row justify-content-center">
        <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
      </div>
      <div class="col-md-6 text-right">
        <button mat-stroked-button (click)="goto()">exitbyhr</button>
      </div>
    </mat-card-content>
</mat-card>

