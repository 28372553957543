<mat-card class="mb-2">
  <mat-card-content>
    <form [formGroup]="addkeyFunction">
      <div class="row">
        <div class="col-md-6">

          <mat-form-field appearance={{themeConfigInput}} class="customMatSelect">
            <mat-label>Department <span class="text-danger">*</span></mat-label>
            <input appEmptySpacesValidations appDropDownSuggestion [SearchList]="departmentList" 
            appRequirematch type="text" placeholder="Select Department" aria-label="department" matInput
              formControlName="DepartmentId" [matAutocomplete]="auto">

            <button class="clearFieldIcon" *ngIf="addkeyFunction.value.DepartmentId" mat-button matSuffix
              mat-icon-button aria-label="Clear" (click)="cliearField()">
              <mat-icon>close</mat-icon>
            </button>

            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngIf="addkeyFunction.get('DepartmentId').hasError('invalidValue')" disabled>No matching value found</mat-option>
              <mat-option [disabled]="isEdit" *ngFor="let item of filteredOptions | async;" [value]="item.label">
                {{item.label}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="addkeyFunction.get('DepartmentId').hasError('required')">
              Department Name Required
            </mat-error>
            <mat-error *ngIf="addkeyFunction.get('DepartmentId').hasError('requireMatch')">
              Select Department Name from given options
            </mat-error>
          </mat-form-field>


        </div>
        <div class="col-md-6">
          <mat-form-field appearance={{themeConfigInput}}>
            <mat-label>Key Function Name <span class="text-danger">*</span></mat-label>
            <input matInput type="text" formControlName="SGRoleName" appValidationImpSpecialChars appEmptySpacesValidations minlength="2">
            <mat-error *ngIf="addkeyFunction.get('SGRoleName').hasError('required')">
              Key Function Name Required
            </mat-error>
            <mat-error *ngIf="addkeyFunction.get('SGRoleName').hasError('maxlength')">
              Only 100 characters
            </mat-error>
            <mat-error *ngIf="addkeyFunction.get('SGRoleName').hasError('minlength')">
              Atleast enter 2 characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3 offset-9">
          <div class="d-flex justify-content-end align-items-center mnh60">
            <button mat-stroked-button (click)="cancel()">
              <mat-icon>clear</mat-icon> Clear
            </button>
            <button mat-flat-button color="primary" class="mr-0" (click)="addkeyFunctions()">
              {{btnLabel}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Department">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Department Name </th>
          <td mat-cell *matCellDef="let keyfunctionlist"> {{keyfunctionlist.Description}} </td>
        </ng-container>
        <ng-container matColumnDef="SGRoleName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Key Function Name </th>
          <td mat-cell *matCellDef="let keyfunctionlist"> {{keyfunctionlist.SGRoleName}} </td>
        </ng-container>
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let keyfunctionlist">
            <mat-icon style="cursor: pointer" (click)="editKeyFunction(keyfunctionlist)">edit</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>
      </table>
    </div>
    <div class="row justify-content-center">
      <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>