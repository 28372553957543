export class wfhAttendance {
    Id : number;
    AsscociateId :string;
    AsscociateName : string;
    Punch1_Date : string;
    Punch2_Date : string;
    InTime : string;
    OutTime : string;
    WorkTime_HHMM : string;
    SUMMARY : string;
    Location : string;
    SignedStatus : number;
    PunchInfoLog : string; 
    asscociateId : string;
    asscociateName: string;
    location:string
    remarks:string;
}