import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-life-cycle',
  templateUrl: './project-life-cycle.component.html',
  styleUrls: ['./project-life-cycle.component.scss']
})
export class ProjectLifeCycleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
