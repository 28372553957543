
<button mat-flat-button type="button" color="primary" id="dashboardbtn" class="pull-right buttons" (click)="addCertificationOrMembership()" > Add</button>
<br>
<br>  
<div class="row">
        <div class="col-md-12">
                <h3 class="dividerHeading">Certification</h3>
        </div>
    </div>
<form class="form-horizontal row justify-content-center">

    <div class="col-lg-12 text-right">
            <section class="pull-right">
    <mat-form-field appearance="{{ themeAppearence }}">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input>
    </mat-form-field>
    </section>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" color="#585858" [fullScreen]="true" type="ball-clip-rotate-multiple"
        size="medium">
        <p class="loading">Loading ...</p>
    </ngx-spinner>

          

    <div class="table-responsive">
        <table mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="SkillGroupName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> SkillGroupName
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.SkillGroupName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="SkillName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> SkillName
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.SkillName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Institution">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Institution
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.Institution}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Specialization">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Specialization
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.Specialization}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ValidFrom">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ValidFrom
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.ValidFrom}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ValidUpto">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ValidUpto
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ValidUpto}}
                                    </td>
                                </ng-container>
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef>Edit</th>
                <td mat-cell *matCellDef="let element">
                        <button mat-flat-button type = "button" (click)="getCertificationEdit(element)"><mat-icon style="cursor: pointer">edit</mat-icon></button>
                    </td>
            </ng-container>
            <ng-container matColumnDef="Delete">
                    <th mat-header-cell *matHeaderCellDef>Delete</th>
                    <td mat-cell *matCellDef="let element">
                            <button mat-flat-button type = "button"  (click)="deleteCertificateOrMembership(element)"><mat-icon>delete</mat-icon></button>
                        </td>
                </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data</td>
            
        </table>
        <div class="row justify-content-center">
                <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
        </div>
    </div>
</form>


<div class="row">
        <div class="col-md-12">
                <h3 class="dividerHeading">Membership</h3>
        </div>
    </div>
<form class="form-horizontal row justify-content-center">
    <div class="col-md-12 text-right">
            <section class="pull-right">
    <mat-form-field appearance="{{ themeAppearence }}">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter1($event)" #input1>
    </mat-form-field>
    </section>
    </div>
          

    <div class="table-responsive">
        <table mat-table #table [dataSource]="dataSource2" matSort>
            <ng-container matColumnDef="MembershipTitle">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> MembershipTitle
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.ProgramTitle}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Institution">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Institution
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.Institution}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Specialization">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Specialization
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.Specialization}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ValidFrom">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ValidFrom
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ValidFrom}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ValidUpto">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ValidUpto
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.ValidUpto}}
                                </td>
                            </ng-container>
            <ng-container matColumnDef="Action2">
                <th mat-header-cell *matHeaderCellDef>Edit</th>
                <td mat-cell *matCellDef="let element">
                        <button mat-flat-button type = "button" (click)="getMembershipEdit(element)"><mat-icon style="cursor: pointer">edit</mat-icon></button>
                    </td>
            </ng-container>
            <ng-container matColumnDef="Delete2">
                    <th mat-header-cell *matHeaderCellDef>Delete</th>
                    <td mat-cell *matCellDef="let element">
                            <button mat-flat-button type = "button" (click)="deleteCertificateOrMembership(element)"><mat-icon>delete</mat-icon></button>
                        </td>
                </ng-container>
            <tr mat-header-row *matHeaderRowDef="membershipCols1"></tr>
        
            <tr mat-row *matRowDef="let row; columns: membershipCols1;"></tr>
            <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data</td>
            
        </table>
        <div class="row justify-content-center">
                <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
        </div>
    </div>
</form>

<ng-template #AssociateProfessionalDialog>
     <mat-toolbar>
            <span>Add Certification/MemberShip Details</span>
            <section class="pull-right buttons">
                <button mat-flat-button id="clearButton"  type="button" (click)="cancelOrclearValues()">
                    <mat-icon>close</mat-icon>
                </button>
            </section>        
    </mat-toolbar>
        <form [formGroup]="myForm" >
            <div mat-dialog-content>
                <div class="row">
                    <div class="col-md-4">
                <mat-form-field appearance="{{ themeAppearence }}">
                    <mat-label>Program Type<span style="color: red"> *</span></mat-label>
                    <mat-select formControlName="programType" name="programType"  (selectionChange)="programTypeChange($event)">
                        <mat-option *ngFor="let q of programType" [value]="q.value">
                            {{q.label}}
                        </mat-option>
                        <mat-error *ngIf="formSubmitted && myForm.controls['programType'].hasError('required')">
                            Program Type is required
                        </mat-error>
                    </mat-select>
                </mat-form-field>
                </div>
                <div class="col-md-4">
                <mat-form-field *ngIf = "certification" appearance="{{ themeAppearence }}">
                    <mat-label>Skill Group<span style="color: red"> *</span></mat-label>
                    <mat-select formControlName="skillGroupId"  name="skillGroup" (selectionChange)="skilGroupChange($event)">
                        <mat-option *ngFor="let q of skillGroups" [value]="q.value">
                            {{q.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['skillGroupId'].hasError('required')">
                            Skill Group is required
                    </mat-error>
                </mat-form-field>
                </div>
                <div class="col-md-4">
                <mat-form-field *ngIf="certification" appearance="{{ themeAppearence }}">
                    <mat-label>Skill Name
                        <span style="color: red"> *</span>
                    </mat-label>
                    <mat-select formControlName="skillId" name="skillId">
                        <mat-option *ngFor="let q of skills" [value]="q.value">
                            {{q.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['skillId'].hasError('required')">
                        Skill Name is required
                    </mat-error>
                </mat-form-field>
                </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                <mat-form-field *ngIf="membership" appearance="{{ themeAppearence }}">
                    <mat-label>MemberShip Title
                        <span style="color: red">*</span>
                    </mat-label>
                    <input type="text" matInput formControlName="programTitle" name="programTitle" maxlength="64"
                         (paste)="$event.preventDefault()" />
                        <mat-error *ngIf="myForm.controls['programTitle'].hasError('required')">
                        
                            MemberShip Title is required
                        </mat-error>
                        <mat-error *ngIf="myForm.controls.programTitle.hasError('pattern')">
                        
                            MemberShip Title is invalid
                        </mat-error>
                </mat-form-field>
                </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                <mat-form-field *ngIf="certification || membership" appearance="{{ themeAppearence }}">
                    <mat-label>
                            Specialization
                            <span style="color: red">*</span>
                    </mat-label>
                    <input type="text" matInput formControlName="specialization" name="specialization" (change)="this.professionalDetail.Specialization = $event.target.value" maxlength="64" (paste)="$event.preventDefault()" />
                    <mat-error *ngIf="myForm.controls['specialization'].hasError('required')">
                    
                        Specialization is required
                    </mat-error>
                    <mat-error *ngIf="myForm.controls['specialization'].hasError('pattern')">
                    
                        Specialization is invalid
                    </mat-error>
                </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field *ngIf="certification || membership" appearance="{{ themeAppearence }}">
                            <mat-label>
                                    Institution
                                    <span style="color: red">*</span>
                            </mat-label>
                            <input type="text" matInput formControlName="institution" name="institution" (change)="this.professionalDetail.Institution = $event.target.value" maxlength="64" (paste)="$event.preventDefault()" />
                            <mat-error *ngIf="myForm.controls['institution'].hasError('required')">
                                    
                                    Institution is required
                                  </mat-error>
                                  <mat-error *ngIf="myForm.controls['institution'].hasError('pattern')">
                                    
                                        Institution is invalid
                                  </mat-error>
                        </mat-form-field>
                        </div>
                        <div class="col-md-6">
                        <mat-form-field *ngIf="certification || membership" appearance="{{ themeAppearence }}">
                                <mat-label>
                                        Valid From
                                        <span style="color: red">*</span>
                                </mat-label>
                                <input type="text" matInput formControlName="validFrom" name="validFrom" maxlength="4" minlength="4" (change)="this.professionalDetail.ValidFrom = $event.target.value" (paste)="$event.preventDefault()" (keypress)="onlyNumbers($event)" (change)="validFromYear($event)"/>
                                <mat-error *ngIf="myForm.controls['validFrom'].hasError('required')">
                                        
                                        Valid From is required
                                      </mat-error>
                                      <mat-error *ngIf="myForm.controls['validFrom'].hasError('pattern')">
                                        
                                            Valid From is invalid
                                      </mat-error>
                                    
                                  
                                      <mat-error *ngIf="validFromErrorMessage">
                                            {{validFromErrorMessage}}
                                          </mat-error>
                            </mat-form-field>
                            </div>
                            <div class="col-md-6">
                            <mat-form-field *ngIf="certification || membership" appearance="{{ themeAppearence }}">
                                <mat-label>
                                    Valid UpTo
                                    <span style="color: red">*</span>
                                </mat-label>
                                <input type="text" matInput formControlName="validUpTo" name="validUpTo" maxlength="4" minlength="4" (change)="this.professionalDetail.ValidUpto = $event.target.value"
                                    (paste)="$event.preventDefault()" (keypress)="onlyNumbers($event)" (change)="validUpToYear($event)" />
                                <mat-error *ngIf="myForm.controls['validUpTo'].hasError('required')">
                            
                                    Valid Up To is required
                                </mat-error>
                                <mat-error *ngIf="myForm.controls['validUpTo'].hasError('pattern')">
                            
                                    Valid Up To is invalid
                                </mat-error>
                
                                <mat-error *ngIf="validUpToErrorMessage">
                                    {{validUpToErrorMessage}}
                                </mat-error>
                            </mat-form-field>
                            </div>
                            </div>
                            <div class="col-lg-12" *ngIf="certification || membership">
                                <section class="pull-right buttons">
                                    <button mat-flat-button id="clearButton" color="primary" type="button" (click)="cancelOrclearValues()">
                                        Cancel</button>
                                    <button mat-flat-button color="primary" id="submitButton" type="submit" *ngIf="!inputDisable" (click)="saveCertificationOrMembership()">
                                        Save</button>
                                    <button mat-flat-button color="primary" id="submitButton" type="submit" *ngIf="inputDisable" (click)="saveCertificationOrMembership()">
                                        Update</button>
                            
                                </section>
                            </div>
            </div>
        </form>
</ng-template>

