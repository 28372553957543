<mat-card class="mb-2">
  <mat-card-content>
    <form [formGroup]="addProficiencyLevel" (ngSubmit)="CreateProficiencyLevelData()">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance={{themeConfigInput}}>

            <mat-label>Proficiency Level Code
              <span class="text-danger">*</span>
            </mat-label>
            <input matInput type="text" name="ProficiencyLevelCode" formControlName="ProficiencyLevelCode"
              appValidations appEmptySpacesValidations minlength="2">
            <mat-error *ngIf="addProficiencyLevel.get('ProficiencyLevelCode').errors">
              <i style="color: red" class="fa fa-exclamation-circle"></i>
              <span style="color: red">Proficiency Level Code Required.</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance={{themeConfigInput}}>
            <mat-label>Proficiency Level Description
              <span class="text-danger">*</span>
            </mat-label>
            <input matInput type="text" name="ProficiencyLevelDescription" formControlName="ProficiencyLevelDescription"
              minlength="2" appEmptySpacesValidations>
            <mat-error *ngIf="addProficiencyLevel.get('ProficiencyLevelDescription').errors">
              <i style="color: red" class="fa fa-exclamation-circle"></i>
              <span style="color: red">Proficiency Level Description Required.</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12 text-right">
          <button (click)="Reset()" type="reset" mat-stroked-button>
            <mat-icon>clear
            </mat-icon>Clear
          </button>
          <button type="submit" mat-flat-button color="primary">
            {{btnLabel}}</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="ProficiencyLevelCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Proficiency Level Code</th>
          <td mat-cell *matCellDef="let element"> {{element.ProficiencyLevelCode}} </td>
        </ng-container>
        <ng-container matColumnDef="ProficiencyLevelDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Proficiency Level Description
          </th>
          <td mat-cell *matCellDef="let element"> {{element.ProficiencyLevelDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="edditProficiencyLevel(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>

      </table>

    </div>
    <div class="row justify-content-center">
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>