import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as servicePath from 'src/app/core/service-paths';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  Url = environment.Url;
  private resourceURL=servicePath.API.Role;

  constructor(private http: HttpClient) {
    
  }

  getAcesstoken(tokenRequestObj){
    return this.http.post( this.Url,tokenRequestObj)

  }

  loggedIn() {
    return !!sessionStorage["token"]
  }

  //to set the generated token into session storage
  setAccessToken(_accessToken: any): any {
    sessionStorage["token"] = _accessToken;
  }


  public GetLoggedInUserEmail()
  {
    let url = environment.AdminMicroService + this.resourceURL.GetLoggedInUserEmail;
    return this.http.get(url,{responseType:"text"});

  }

}



