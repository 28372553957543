<mat-card class="mb-2">
  <mat-card-content>
      <div class="row">
        <div class="col-md-8 col-md-offset-2">         
          <div class="row">
            <div class="text-right">
              <div class="mt-10 pr-15">
              <button type="button" (click)="openAddKRAScale('add')" mat-flat-button color="primary"><mat-icon>add
              </mat-icon>Add KRA Scale</button>
            </div>
            </div>
          </div>
        </div>   
  </div>
  </mat-card-content>
</mat-card>

  <mat-card matSort>
  <mat-card-content>
    <div class="table-responsive">
                  <table class="mat-elevation-z8 col-12" mat-table [dataSource]="dataSource" cdkDropList cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)" matSort>
                    <!-- Name Column -->
                    <ng-container matColumnDef="ScaleTitle">
                      <th mat-header-cell *matHeaderCellDef>Scale Title</th>
                      <td mat-cell *matCellDef="let element"> {{element.ScaleTitle}} </td>
                    </ng-container>
                    <ng-container matColumnDef="MinimumScale">
                        <th mat-header-cell *matHeaderCellDef>Minimum Scale</th>
                        <td mat-cell *matCellDef="let element"> {{element.MinimumScale}} </td>
                      </ng-container>
                      <ng-container matColumnDef="MaximumScale">
                        <th mat-header-cell *matHeaderCellDef>MaximumScale</th>
                        <td mat-cell *matCellDef="let element"> {{element.MaximumScale}} </td>
                      </ng-container>
                
                    <!-- Weight Column -->
                    <ng-container matColumnDef="View">
                      <th   class="mnw-70 w-70" mat-header-cell *matHeaderCellDef>View </th>
                      <td class="mnw-70 w-70" mat-cell *matCellDef="let element"> 
                          <button type="button" mat-button mat-icon-button color="primary" (click)="viewDialog(element.ScaleID)">
                              <mat-icon class="mat-18">visibility</mat-icon>
                      </button> </td>
                    </ng-container>
                    <ng-container matColumnDef="Edit">
                        <th   class="mnw-70 w-70" mat-header-cell *matHeaderCellDef>Edit </th>
                        <td class="mnw-70 w-70" mat-cell *matCellDef="let element"> 
                            <button type="button" mat-icon-button color="primary" (click)="onEditKRAScaleDescription(element)">
                                <mat-icon class="mat-18">edit</mat-icon>
                        </button> </td>
                      </ng-container>
                      <ng-container matColumnDef="Delete">
                        <th   class="mnw-70 w-70" mat-header-cell *matHeaderCellDef>Delete </th>
                        <td class="mnw-70 w-70" mat-cell *matCellDef="let element"> 
                            <button type="button" mat-icon-button color="primary"
                                (click)="onDeleteKRAScale(element.ScaleID)">
                                <mat-icon class="mat-18">delete</mat-icon>
                        </button> </td>
                      </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  </div>
    <div class="row justify-content-center">
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #viewScale>
    <h1 mat-dialog-title>View KRA Scale Descriptions</h1>
    <hr>
    <mat-dialog-content>
        <h5>{{dialogText}}</h5>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #addScale>
    <h1 mat-dialog-title>KRAScale Title</h1>
    <hr>
    <form [formGroup]="myForm" (ngSubmit)="saveKRAScale(myForm.value)">
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Scale Title</mat-label>
                    <input matInput class="form-group" formControlName="ScaleTitle">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Minimum Scale</mat-label>
                    <input matInput type="number" formControlName="MinimumScale" readonly disabled>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Maximum Scale</mat-label>
                    <input matInput type="number" maxlength="30" (keyup)="onMaximumScaleChange(myForm.value)" formControlName="MaximumScale">
                </mat-form-field>
            </div>
        </div>
        
            <div *ngIf="descriptionHide">
                <label>Description</label>
                <div formArrayName="ScaleDetails" *ngFor="let ScaleDescription of myForm.get('ScaleDetails')['controls']; let i=index">
                    <div [formGroupName]="i">
                        <div  class="col-md-12">
                        <mat-form-field>
                            <mat-label>{{i+1}}</mat-label>
                            <input matInput formControlName="ScaleDescription" placeholder="Scale Description" type="text" required>
                        </mat-form-field>
                    </div>
                    </div>
                </div>
            </div>
        
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="primary" type="submit">{{saveButton}}</button>
        <button mat-button color="primary" type="button" (click)="resetForm()">Clear</button>
        <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</form>
</ng-template>
