<h2 mat-dialog-title>Alert
</h2>

<mat-dialog-content >
    <mat-icon class="warning-css">error_outline</mat-icon>&nbsp;<span>Please adjust the task(s) start date and end date according to KT start date and end date.</span>
</mat-dialog-content>

<mat-dialog-actions style="float: right">
    <button mat-flat-button mat-dialog-close color = "primary">Close</button>
  </mat-dialog-actions>
