<h1 mat-dialog-title> Client Billing Role(s)</h1>
<div mat-dialog-content [hidden]="!isAllSelected">
  <h5> <i style="color: red" class="fa fa-exclamation-circle"></i>
    Please Select Project</h5>
</div>

<mat-dialog-content [hidden]="!isProjectSelected">
  <div class="table-responsive">
    <table class="col-12" mat-table [dataSource]="clientBillingRoleData" matSort>

      <ng-container matColumnDef="checked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Select</th>
        <td mat-cell *matCellDef="let element">
          <mat-radio-button (change)="clientbillingRoleSelected(element)" *ngIf="element['AvailablePositions'] > 0">
          </mat-radio-button>
        </td>
      </ng-container>

      <ng-container matColumnDef="ClientBillingRoleName">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header  class="font-weight-bold">ClientBilling Role Name</th>
        <td mat-cell *matCellDef="let element"> {{element.ClientBillingRoleName}} </td>
      </ng-container>

      <ng-container matColumnDef="ClientBillingPercentage">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header  class="font-weight-bold">Billing % </th>
        <td mat-cell *matCellDef="let element"> {{element.ClientBillingPercentage}} </td>
      </ng-container>

      <ng-container matColumnDef="NoOfPositions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Total Position</th>
        <td mat-cell *matCellDef="let element"> {{element.NoOfPositions}} </td>
      </ng-container>

    
      <ng-container matColumnDef="StartDate">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header  class="font-weight-bold">Billing Start Date</th>
        <td mat-cell *matCellDef="let element"> {{element.StartDate}} </td>
      </ng-container>
      <ng-container matColumnDef="AllocationCount">
        <th mat-header-cell *matHeaderCellDef   mat-sort-header class="font-weight-bold">Allocated Position(s)</th>
        <td mat-cell *matCellDef="let element"> {{element.AllocationCount}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data</td>
      </tr>

    </table>
  </div>
  <div class="row justify-content-center">
    <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
  </div>
  </mat-dialog-content>

  <div class="col-md-12 text-right">
    <button mat-button (click)="onNoClick()">Close</button>
    <button mat-flat-button color="primary" type="button" style="cursor:pointer;" *ngIf="isProjectSelected"
      (click)="selectedCBR()" [hidden] = "isApplyButton">Apply</button>
  </div>
















  