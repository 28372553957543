<mat-card>
    <div class="row">
        <div class="col-md-5">
            <mat-form-field >
                <mat-label>Year
                    <span class="text-danger">*</span>
                </mat-label>
                <mat-select  [formControl]="Year" >
                    <mat-option *ngFor="let item of yearsList"
                        [value]="item">{{item}}
                    </mat-option>
                </mat-select>                
            </mat-form-field>
            </div>
            <div class="col-md-5">
            <mat-form-field >
                <mat-label>Month
                    <span class="text-danger">*</span>
                </mat-label>
                <mat-select  [formControl]="Month"  >
                    <mat-option *ngFor="let item of montsList"
                        [value]="item.value">{{item.label}}
                    </mat-option>
                </mat-select>
                
            </mat-form-field>
            <div class="col-md-12 text-right">
                <button mat-flat-button color="primary" class="mr-0"  (click)="onSubmit()">Generate Report</button>
             </div>    
            </div>
        </div>   

</mat-card>