<mat-card [hidden]="!pageload">
    <mat-card-content>
      <h4> KT Plan</h4>
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="employeeId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Code </th>
                <td mat-cell *matCellDef="let element"> {{element.EmployeeCode}} </td>
              </ng-container>
      
              <ng-container matColumnDef="employeeName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Name </th>
                <td mat-cell *matCellDef="let element"> {{element.EmployeeName}} </td>
              </ng-container>
      
              <ng-container matColumnDef="designation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Designation </th>
                <td mat-cell *matCellDef="let element"> {{element.Designation}} </td>
              </ng-container>
              
              <ng-container matColumnDef="exitDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Exit Date </th>
                <td mat-cell *matCellDef="let element"> {{element.ExitDate}} </td>
      
              </ng-container>
    
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> status </th>
                <td mat-cell *matCellDef="let element"> {{element.StatusDesc}} </td>
      
              </ng-container>
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Action </th>
                <td mat-cell  *matCellDef="let element">
                    <a mat-stroked-button color="primary" [routerLink]="['/shared/KtForm/TeamLead', element.EmployeeId,  element.ProjectId]">Transition Plan</a>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
              </tr>
            </table>
          </div>
          <div class="row justify-content-center">
            <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
          </div>
    </mat-card-content>
</mat-card>
