<mat-card class="mb-2">
  <mat-card-content>
    <form [formGroup]="addServiceType">
      <div class="row">
        <div class=" col-md-5 ">
          <mat-form-field appearance="{{ themeConfigInput }}">
            <mat-label>Service Type Name<span class="text-danger"> *</span></mat-label>
            <input matInput formControlName="ServiceTypeName" appValidationImpSpecialChars appEmptySpacesValidations minlength="2" />
            <mat-error>
              <span *ngIf="(addServiceType.get('ServiceTypeName').errors)">
                Service Name required</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class=" col-md-5 ">
          <mat-form-field appearance="{{ themeConfigInput }}">
            <mat-label>Service Type Description<span class="text-danger"> *</span></mat-label>
            <input matInput formControlName="ServiceDescription" appEmptySpacesValidations minlength="2" />
            <mat-error>
              <span *ngIf="addServiceType.get('ServiceDescription').errors">
                Service Type Description Required</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-slide-toggle formControlName="IsActive" color="primary" [disabled] = "this.btnLabel == 'Save'">Is Active</mat-slide-toggle>
        </div>
      </div>
      <div class="col-md-12 text-right">
        <button mat-stroked-button (click)="Reset()">
          <mat-icon>close</mat-icon>Clear
        </button>
        <button mat-flat-button color="primary" (click)="CreateServiceType()">
          {{ btnLabel }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="ServiceTypeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">
            Service Type Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ServiceTypeName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ServiceDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">
            Service Type Description
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ServiceDescription }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef class="font-weight-bold">
            Edit
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="cursor: pointer" (click)="setEditObj(element)">edit</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>
      </table>
    </div>
    <div class="row justify-content-center">
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>