<!--Associate Exit Card-->
<div class="container-fluid">
    <mat-card class="mb-2">
        <mat-card-header>
            <mat-card-title *ngIf="roleName=='HRM'" >HRM Checklist</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <!-- <div class="col-md-6 font-weight-bold">
            Associate Exit Actions
          </div> -->
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSourceAssociateExit" matSort>
                    <ng-container matColumnDef="EmployeeCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Emp Id </th>
                        <td mat-cell *matCellDef="let element">{{element.EmployeeCode}}</td>
                    </ng-container>

                    <ng-container matColumnDef="EmployeeName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Name
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.EmployeeName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Designation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Designation
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.Designation}}</td>
                    </ng-container>

                    <ng-container matColumnDef="ExitDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Exit Date </th>
                        <td mat-cell *matCellDef="let element">{{element.ExitDate}}</td>
                    </ng-container>

                    <ng-container matColumnDef="StatusCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Status </th>
                        <td mat-cell *matCellDef="let element">{{element.StatusDesc}}</td>
                    </ng-container>
                    <ng-container matColumnDef="View">
                        <th mat-header-cell *matHeaderCellDef
                            mat-sort-header class="font-weight-bold">
                            View </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="(element.StatusCode=='ResignationInProgress'  ||  element.StatusCode === 'ReadyForClearance' )&& roleName == 'HRM' &&  element.SubStatusCode ==='DepartmentActivityCompleted'" 
                            style="cursor: pointer" (click)="getExitChecklist(element)">visibility</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            class="font-weight-bold">
                            Action </th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                *ngIf="element.StatusCode=='ResignationInProgress' && (roleName == 'HRM') && element.SubStatusCode === 'DepartmentActivityInProgress'"
                                mat-flat-button color="primary" (click)="getExitChecklist(element)">
                                Approve Checklist
                            </button>
                        </td>


                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumnsAssociateExit"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumnsAssociateExit"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No Records</td>
                    </tr>
                </table>
            </div>
            <div class="row justify-content-center">
                <mat-paginator #exitPaginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons>
                </mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>