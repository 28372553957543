<mat-card class="mb-2">
  <mat-card-content>
    <form [formGroup]="addPracticeArea" (ngSubmit)="addpracticeAreas()">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance={{themeConfigInput}}>

            <mat-label>Practice Area Code
              <span class="text-danger">*</span>
            </mat-label>
            <input matInput type="text" formControlName="PracticeAreaCode" appPracticeAreaValidations appEmptySpacesValidations minlength="2" maxlength="100">
            <mat-error *ngIf="addPracticeArea.get('PracticeAreaCode').errors && !addPracticeArea.get('PracticeAreaCode').hasError('maxlength')">
              <i style="color: red" class="fa fa-exclamation-circle"></i>
              <span style="color: red">Practice Area Code Required</span>
            </mat-error>
            <mat-error *ngIf="addPracticeArea.get('PracticeAreaCode').hasError('maxlength')">
              <span style="color: red">Only 100 characters.</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance={{themeConfigInput}}>
            <mat-label>Practice Area Description
              <span class="text-danger">*</span>
            </mat-label>
            <input matInput type="text" formControlName="PracticeAreaDescription"  minlength="2" maxlength="100">
            <mat-error *ngIf="addPracticeArea.get('PracticeAreaDescription').errors && !addPracticeArea.get('PracticeAreaDescription').hasError('maxlength')">
              <i style="color: red" class="fa fa-exclamation-circle"></i>
              <span style="color: red"> Practice Area Description Required</span>
            </mat-error>
            <mat-error *ngIf="addPracticeArea.get('PracticeAreaDescription').hasError('maxlength')">
              <span style="color: red">Only 100 characters.</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">

          <mat-form-field appearance="{{themeConfigInput}}" (click)="getFormControl('PracticeAreaHeadId')"
          (keypress)="getFormControl('PracticeAreaHeadId')">
          <mat-label>Practice Area Head
            <span class="text-danger"> *</span>
          </mat-label>
          <input appDropDownSuggestion [SearchList]="usersList" type="text" appRequirematch #PracticeAreaHeadId
            (keydown.enter)="PracticeAreaHeadId.blur()" matInput formControlName="PracticeAreaHeadId" [matAutocomplete]="auto"
            appEmptySpacesValidations minlength="2" />
          <button class="clearFieldIcon" *ngIf="addPracticeArea.value.PracticeAreaHeadId" mat-button matSuffix
            mat-icon-button aria-label="Clear" (click)="clearInput($event, 'PracticeAreaHeadId')">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngIf="addPracticeArea.get('PracticeAreaHeadId').hasError('invalidValue')" disabled>No matching
              value found</mat-option>
            <mat-option *ngFor="let option of filteredOptionsName | async" [value]="option">
              {{ option.label }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="addPracticeArea.get('PracticeAreaHeadId').hasError('required')">
            Associate Required
          </mat-error>
          <mat-error *ngIf="addPracticeArea.get('PracticeAreaHeadId').hasError('requireMatch')">
            Select Associate from given options
          </mat-error>
        </mat-form-field>
        </div>

        <div class="col-md-12 text-right">
          <button (click)="Reset()" type="reset" mat-stroked-button>
            <mat-icon>clear
            </mat-icon>Clear
          </button>
          <button type="submit" mat-flat-button color="primary">
            {{btnLabel}}</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="PracticeAreaCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Practice Area Code</th>
          <td mat-cell *matCellDef="let element"> {{element.PracticeAreaCode}} </td>
        </ng-container>
        <ng-container matColumnDef="PracticeAreaDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Practice Area Description </th>
          <td mat-cell *matCellDef="let element"> {{element.PracticeAreaDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="PracticeAreaHeadName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Practice Area Head </th>
          <td mat-cell *matCellDef="let element"> {{element.PracticeAreaHeadName}} </td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editPracticeArea(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>

      </table>

    </div>
    <div class="row justify-content-center">
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>