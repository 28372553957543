export class AssociateLongLeaveData {
    EmployeeId : number;
    ProgramManagerName : string;
    ReportingManagerName : string;
    ProjectName : string;
    LongLeaveStartDate : string;
    IsBillable: string;
    Reason: string;
    IsCritical : string;
    EmployeeName : string;
    TentativeJoinDate : string;
    IsLongLeave : boolean;
    IsMaternity : boolean;
}