<div class="container-fluid">
    <h3 mat-dialog-title>Remarks</h3>
    
    </div>
    <mat-dialog-content>
    
        <form [formGroup]="closeProjectData" >
          
          <!-- <div class="col-5">
            <mat-form-field appearance={{themeConfigInput}}>
              <mat-label>End Date
                <span class="text-danger"> *</span>
              </mat-label>
              <input matInput formControlName="ActualEndDate" [matDatepicker]="ActualEndDate" (dateChange)="validFromYear($event.value)">
              <mat-datepicker-toggle matSuffix [for]="ActualEndDate"></mat-datepicker-toggle>
              <mat-datepicker #ActualEndDate color="primary"></mat-datepicker>
              <mat-error *ngIf="closeProjectData.get('ActualEndDate').hasError('required')">
                End Date Required
              </mat-error>
              <mat-error *ngIf="validFromErrorMessage">
                {{validFromErrorMessage}}
              </mat-error>
          
            </mat-form-field>
          
          </div> -->
          <div class="col-12">
            <mat-form-field appearance={{themeConfigInput}}>
              <mat-label>Remarks
                <span class="text-danger"> *</span>
              </mat-label>
              <textarea matInput formControlName="rejectRemarks"></textarea>
              <mat-error *ngIf="closeProjectData.get('rejectRemarks').hasError('required')">
                Remarks is Required
              </mat-error>
            </mat-form-field>
          </div>
          
          
          <div class="row">
            <div class="col-12 text-right">
              <button mat-stroked-button (click)="onNoClick()">
                <mat-icon>close</mat-icon>Cancel
              </button>
              <button type="submit" mat-flat-button color="primary" (click)="save()" [disabled]="!closeProjectData.valid">Submit</button>     
            </div>
              </div>
          </form>
    
    </mat-dialog-content>
    
    
    
    