import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internal-billing-roles',
  templateUrl: './internal-billing-roles.component.html',
  styleUrls: ['./internal-billing-roles.component.scss']
})
export class InternalBillingRolesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
