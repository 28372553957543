export class ProjectType
{
    ProjectTypeId:number;
    ProjectType:string;
}

export class ProjectTypeData{
    Description : string;
    ProjectTypeCode : string;
    ProjectTypeId: number;
} 