<div class="row">
    <div class="col-9 text-left">
        <h6>Project Details of <span *ngIf="empName">{{empName}}</span></h6>
    </div>
    <div class="col-3 text-right">
        <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>  
    </div>
  </div> 

<div mat-dialog-content>
    <div class="text-right">
        <div class="color-box-css"></div>
        <span class="color-box-label-css">Current Project</span>
    </div>
    
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="ProjectName">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold">ProjectName</th>
            <td mat-cell *matCellDef="let element"> {{element.ProjectName}} </td>

        </ng-container>

        <ng-container matColumnDef="ClientName">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Client Name </th>
            <td mat-cell *matCellDef="let element"> {{element.ClientName }} </td>
        </ng-container>

        <ng-container matColumnDef="EffectiveDate">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Effective From </th>
            <td mat-cell *matCellDef="let element"> {{element.EffectiveDate }} </td>
        </ng-container>
        <ng-container matColumnDef="ReleaseDate">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Release Date </th>
            <td mat-cell *matCellDef="let element"> {{element.ReleaseDate }} </td>
        </ng-container>
        <ng-container matColumnDef="IsBillable">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> IsBillable </th>
            <td mat-cell *matCellDef="let element"> {{element.IsBillable }} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"
            [ngClass]="row.IsActive ? 'active-project' : 'inactive-project'"></tr>
    </table>
</div>
<!-- <div class="row justify-content-center">
    <mat-paginator #projectdetailsPaginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
</div> -->