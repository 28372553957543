
<app-view-regularization-applied-associates></app-view-regularization-applied-associates>

<mat-card>
  <mat-card-header>
    <mat-card-title>Projects</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="table-responsive">
      <table
        class="mat-elevation-z8 col-12"
        mat-table
        [dataSource]="dataSource"
        cdkDropList
        cdkDropListOrientation="horizontal"
        matSort
      >
        <ng-container matColumnDef="ProjectName">
          <th
            mat-header-cell
            cdkDrag
            *matHeaderCellDef
            mat-sort-header
            class="font-weight-bold"
          >
            Project Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ProjectName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ManagerName">
          <th
            mat-header-cell
            cdkDrag
            *matHeaderCellDef
            mat-sort-header
            class="font-weight-bold"
          >
            Project Manager
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ManagerName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="PracticeAreaCode">
          <th
            mat-header-cell
            cdkDrag
            *matHeaderCellDef
            mat-sort-header
            class="font-weight-bold"
          >
            Technology
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.PracticeAreaCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ClientName">
          <th
            mat-header-cell
            cdkDrag
            *matHeaderCellDef
            mat-sort-header
            class="font-weight-bold"
          >
            Client Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ClientName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ActualStartDate">
          <th
            mat-header-cell
            cdkDrag
            *matHeaderCellDef
            mat-sort-header
            class="font-weight-bold"
          >
            Start Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ActualStartDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ProjectState">
          <th
            mat-header-cell
            cdkDrag
            *matHeaderCellDef
            mat-sort-header
            class="font-weight-bold"
          >
            Status
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ProjectState }}
          </td>
        </ng-container>

        <ng-container matColumnDef="View">
          <th mat-header-cell *matHeaderCellDef class="font-weight-bold">
            View
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="cursor: pointer" (click)="ViewProject(element)"
              >visibility</mat-icon
            >
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No data</td>
        </tr>
      </table>
    </div>
    <div class="row justify-content-center">
      <mat-paginator #projectdetailsPaginator
        [pageSizeOptions]="[10, 20, 30]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>


