<div style="padding: 15px;">
    
    <app-view-associate-exit-details></app-view-associate-exit-details>
    <!-- checklist details starts -->
    <mat-card *ngIf="pageload">
        <form  [formGroup]="checklistForm">  
            <mat-card-content >
                <div class="container-fluid">
                    <h2 >Activities</h2>
                    <div class="p-2 mb-2" *ngFor="let element of activityData.ActivityDetails; let i=index">
                        <div class="row" *ngIf="canShowTitle(element,i)">
                            <div class="col-md-12">
                                    <mat-label>
                                        <h3><b>{{activityType.get(element.ActivityId)}}</b></h3>
                                    </mat-label>
                                </div>
                    </div>
                        <div class="row" >
                            <div class="col-md-6 col-xs-12">
                                <mat-checkbox [formControlName]="'Checklist'+i">
                                    <span class="mat-checkbox-label">
                                        {{ checklist.get(element.ActivityId) }}<span *ngIf="IsRequired.get(element.ActivityId)==true" class="text-danger"> *</span>
                                    </span>
                                </mat-checkbox>   
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <mat-form-field appearance={{themeConfigInput}}>
                                    <mat-label>Comments</mat-label>
                                    <textarea matInput type="textarea" [formControlName]="'Comment'+i" style="resize:none"></textarea>
                                </mat-form-field>
                            </div>
                        </div>       
                    </div>
        
                    <div class="p-3 m-2">
                        <label>Remarks</label>
                        <mat-form-field appearance={{themeConfigInput}}>
                            <mat-label>Remarks</mat-label>
                            <textarea matInput type="text" formControlName="Remarks" style="resize:none"></textarea>
                        </mat-form-field>
                    </div>
                </div> 
            </mat-card-content>
            
            <div *ngIf="!disableform" class="col-12 text-right">
                <button mat-flat-button color="primary" (click)="Save()">
                    Save
                </button>
                <button mat-flat-button color="primary" (click)="Submit()">
                    Submit
                </button>
            </div>    
        </form>
        </mat-card>
</div>