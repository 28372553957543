<!--Associate Exit Card-->
<mat-card class="mb-2" [hidden]="!(dataSourceAssociateExit && dataSourceAssociateExit.data.length > 0)">
  <mat-card-header>
    <mat-card-title>Associate Exit Actions</mat-card-title>
  </mat-card-header>
    <mat-card-content>
      <!-- <div class="col-md-6 font-weight-bold">
        Associate Exit Actions
      </div> -->
        <div class ="table-responsive">
            <table mat-table [dataSource]="dataSourceAssociateExit" matSort>
                <ng-container matColumnDef="EmployeeCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Emp Id </th>
                    <td mat-cell *matCellDef="let element">{{element.EmployeeCode}}</td>
                  </ng-container>
  
                <ng-container matColumnDef="EmployeeName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Name </th>
                    <td mat-cell *matCellDef="let element">{{element.EmployeeName}}</td>
                </ng-container>
  
                <ng-container matColumnDef="Designation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Designation </th>
                    <td mat-cell *matCellDef="let element">{{element.Designation}}</td>
                  </ng-container>
  
                  <ng-container matColumnDef="ExitDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Exit Date </th>
                    <td mat-cell *matCellDef="let element">{{element.ExitDate}}</td>
                  </ng-container>
  
                  <ng-container matColumnDef="StatusCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Status </th>
                    <td mat-cell *matCellDef="let element">{{element.StatusDesc}}</td>
                  </ng-container>
  
                  <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Action </th>
                    <td mat-cell  *matCellDef="let element">
                      <mat-icon *ngIf="element.StatusCode!='ClearanceGiven' && element.StatusCode!='RevokeInitiated'" style="cursor: pointer" (click)="viewResignationDets(element)">visibility</mat-icon>
                      <button *ngIf="element.StatusCode=='ClearanceGiven'" mat-flat-button color="primary" (click)="getclearanceForm(element)">Clearance Form</button>
                      <mat-icon *ngIf="element.StatusCode=='RevokeInitiated'" style="cursor: pointer;color: green" (click)= "revokeResignation(element,'Approve')" >check</mat-icon>
                      <mat-icon *ngIf="element.StatusCode=='RevokeInitiated'"  style="cursor: pointer;color: red" (click)= "revokeResignation(element,'Reject')">clear</mat-icon>
                      <mat-icon *ngIf="element.StatusCode=='RevokeInitiated'" [matTooltip]="element.WithdrawReason" style="cursor: pointer">info</mat-icon>
                    </td>
                  </ng-container>
  
                  <tr mat-header-row *matHeaderRowDef="displayColumnsAssociateExit"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayColumnsAssociateExit"></tr>
            </table>
        </div>
        <div class="row justify-content-center">
          <mat-paginator #exitPaginator [pageSizeOptions]="[10, 20, 30]"
                        showFirstLastButtons >
          </mat-paginator>
        </div>
    </mat-card-content>
  </mat-card>