<mat-card class="mb-2">
  <mat-card-content>
    <form [formGroup]="searchDepartment">
      <div class="row">
        <div class="col-md-9">
          <mat-form-field appearance={{themeConfigInput}}>
            <mat-label>Department<span class="text-danger"> *</span></mat-label>
            <mat-select formControlName="DepartmentId" name="DepartmentId"
              (selectionChange)="searchRoleByDepartment(searchDepartment.value.DepartmentId)" size="30">
              <mat-option disabled>Select Deparment</mat-option>
              <mat-option *ngFor="let dept of departmentList" [value]="dept.value">
                {{dept.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <div class="d-flex justify-content-end align-items-center mnh60">
            <button mat-stroked-button (click)="cancel()">
              <mat-icon>clear</mat-icon>
              Clear
            </button>
            <button type="submit" mat-flat-button color="primary" [disabled]=!showadd
              (click)="createRole(searchDepartment.value.DepartmentId)">ADD</button>
          </div>
        </div>
      </div>
    </form>

  </mat-card-content>
</mat-card>

<mat-card [hidden]="!showTable">
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="FunctionalRole">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Functional Role</th>
          <td mat-cell *matCellDef="let role"> {{role.SGRolePrefix.PrefixName}} {{role.SGRoleSuffix.SuffixName}}
            {{role.SGRole.SGRoleName}} </td>
        </ng-container>

        <ng-container matColumnDef="RoleDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">Functional Role Description
          </th>
          <td mat-cell *matCellDef="let role"> {{role.RoleDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let role">
            <mat-icon style="cursor: pointer" (click)="editRole(role)">edit</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>
      </table>
    </div>
    <div class="row justify-content-center">
      <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>