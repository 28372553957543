<app-view-regularization-applied-associates></app-view-regularization-applied-associates>
  
<mat-card [hidden]="!pageload || dashboard === 'ServiceDashboard'">
  <mat-card-header>
    <mat-card-title>Projects</mat-card-title>
  </mat-card-header>
    <mat-card-content>
      <div class="table-responsive">
        <table
          class="mat-elevation-z8 col-12"
          mat-table
          [dataSource]="dataSource"
          cdkDropList
          cdkDropListOrientation="horizontal"
          matSort
        >
          <ng-container matColumnDef="ProjectName">
            <th
              mat-header-cell
              cdkDrag
              *matHeaderCellDef
              mat-sort-header
              class="font-weight-bold"
            >
              Project Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.ProjectName }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="ManagerName">
            <th
              mat-header-cell
              cdkDrag
              *matHeaderCellDef
              mat-sort-header
              class="font-weight-bold"
            >
              Project Manager
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.ManagerName }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="PracticeAreaCode">
            <th
              mat-header-cell
              cdkDrag
              *matHeaderCellDef
              mat-sort-header
              class="font-weight-bold"
            >
              Technology
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.PracticeAreaCode }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="ClientName">
            <th
              mat-header-cell
              cdkDrag
              *matHeaderCellDef
              mat-sort-header
              class="font-weight-bold"
            >
              Client Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.ClientName }}
            </td>
          </ng-container>
          <!-- <ng-container matColumnDef="ActualStartDate">
            <th
              mat-header-cell
              cdkDrag
              *matHeaderCellDef
              mat-sort-header
              class="font-weight-bold"
            >
              Start Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.ActualStartDate }}
            </td>
          </ng-container> -->
          <ng-container matColumnDef="ProjectState">
            <th
              mat-header-cell
              cdkDrag
              *matHeaderCellDef
              mat-sort-header
              class="font-weight-bold"
            >
              Status
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.ProjectState }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold">
              View
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="primary" (click)="ViewProject(element)">Open</button>
              </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">No data</td>
          </tr>
        </table>
      </div>
      <div class="row justify-content-center">
        <mat-paginator
          [pageSizeOptions]="[10, 20, 30]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card [hidden]="!(dataSource1 && dataSource1.data.length > 0)">
    <mat-card-header>
      <mat-card-title>Associate Exit Actions
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class ="table-responsive">
            <table mat-table [dataSource]="dataSource1" matSort>
                <ng-container matColumnDef="EmployeeCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Code </th>
                    <td mat-cell *matCellDef="let element">{{element.EmployeeCode}}</td>
                  </ng-container>

                <ng-container matColumnDef="EmployeeName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Employee Name </th>
                    <td mat-cell *matCellDef="let element">{{element.EmployeeName}}</td>
                </ng-container>

                <ng-container matColumnDef="Designation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Designation </th>
                    <td mat-cell *matCellDef="let element">{{element.Designation}}</td>
                  </ng-container>

                  <ng-container matColumnDef="ExitDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Exit Date </th>
                    <td mat-cell *matCellDef="let element">{{element.ExitDate}}</td>
                  </ng-container>

                  <ng-container matColumnDef="StatusCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Status</th>
                    <td mat-cell *matCellDef="let element">{{element.StatusDesc}}</td>
                  </ng-container>

                  <ng-container matColumnDef="TransitionPlan">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Transition Plan </th>
                    <td mat-cell  *matCellDef="let element">
                      <button [routerLink]="['/shared/KtForm/TeamLead', element.EmployeeId,  element.ProjectId]" class="mr-0 ml-0 transition-plan" mat-flat-button color="primary"><mat-icon>assignment</mat-icon>Transition Plan </button>
                      <span *ngIf="element.SubStatusCode==='' || element.SubStatusCode===null">
                        <img (click)="UpdateKTPLan(element.EmployeeId)" matTooltip="Transition Not Required" style="cursor: pointer"
                          src="../../../../../assets/icons/TransitionNotRequired.svg" alt="Transition Not Required" />
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="View">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> View</th>
                    <td mat-cell  *matCellDef="let element">
                      <mat-icon *ngIf="element.StatusCode !== 'ResignationSubmitted'" style="cursor: pointer" (click)="PMSubmissionScreen(element)"
                            >visibility</mat-icon>
                    </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Action </th>
                    <td mat-cell  *matCellDef="let element">
                      <button *ngIf="(element.StatusCode==='ResignationApproved' || element.StatusCode=== 'ResignationInProgress' || element.StatusCode=== 'ReadyForClearance') && element.TransitionRequired != false" 
                      [routerLink]="['/shared/KtForm/TeamLead', element.EmployeeId,  element.ProjectId]" class="mr-0 ml-0 transition-plan" mat-flat-button color="primary"><mat-icon>assignment</mat-icon>Transition Plan </button>
                      <span *ngIf="(element.StatusCode==='ResignationApproved' || element.StatusCode=== 'ResignationInProgress' || element.StatusCode=== 'ReadyForClearance') && (element.SubStatusCode==='' || element.SubStatusCode===null) && element.TransitionRequired != false">
                        <img (click)="UpdateKTPLan(element.EmployeeId)" matTooltip="Transition Not Required" style="cursor: pointer"
                          src="../../../../../assets/icons/TransitionNotRequired.svg" alt="Transition Not Required" />
                      </span>
                      <button class="mr-0 ml-0" *ngIf="dashboard == 'ServiceDashboard' && (element.StatusCode === 'ResignationSubmitted')"
                                mat-flat-button color="primary" (click)="PMSubmissionScreen(element)">
                                Review
                      </button>
                      <button class="mr-0 ml-0" [disabled]="true" *ngIf="element.TransitionRequired === false"  mat-flat-button color="primary">
                        {{element.SubStatusDesc}}
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data</td>
          </tr>
            </table>
        </div>
        <div class="row justify-content-center">
          <mat-paginator #exitPaginator
          [pageSizeOptions]="[10, 20, 30]"
          showFirstLastButtons
        ></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>

<app-reporting-manager-dashboard></app-reporting-manager-dashboard>

  