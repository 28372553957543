<app-view-associate-exit-details></app-view-associate-exit-details>

<!-- <div class="col-12 text-right">
    <button mat-flat-button color="primary" *ngIf="showButton" (click)="SubmitForAssociateExit()">{{btnLabel}}</button>
</div> -->
<h3>Departments Activity List</h3>
<mat-accordion>
    <div *ngFor="let department of activityData">
        <mat-expansion-panel *ngIf="department!==null">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Department-{{department.DepartmentName}}
                </mat-panel-title>
                <mat-panel-description>
                    <mat-icon *ngIf="department.StatusCode==='DepartmentActivityCompleted'" class="material-icons color_green">
                        check_circle</mat-icon>
                    <mat-icon *ngIf="department.StatusCode!=='DepartmentActivityCompleted'" matSuffix><i
                            class="fa fa-exclamation-circle" aria-hidden="true"></i></mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card-content *ngIf="department.StatusCode==='DepartmentActivityCompleted'">
                <div class="container-fluid">
                    <div class="p-2 mb-2" *ngFor="let element of department.ActivityDetails; let i=index">
                            <div class="row" *ngIf="canShowTitle(element,i)">
                                    <div class="col-md-12">
                                            <mat-label>
                                                <h3><b>{{activityType.get(element.ActivityId)}}</b></h3>
                                            </mat-label>
                                        </div>
                            </div>
                        <div class="row">
                            <div class="col-md-6 col-xs-12">
                                <mat-checkbox [disabled]="true" [checked]="element.ActivityValue==='yes'">
                                    <span class="mat-checkbox-label">
                                        {{ checklist.get(element.ActivityId) }}<span *ngIf="IsRequired.get(element.ActivityId)==true" class="text-danger"> *</span>
                                    </span>
                                </mat-checkbox>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <mat-form-field appearance={{themeConfigInput}}>
                                    <mat-label>Comments</mat-label>
                                    <textarea matInput type="text" [value]="element.Remarks" style="resize:none"
                                        readonly></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="p-3 m-2">
                        <label>Remarks</label>
                        <mat-form-field appearance={{themeConfigInput}}>
                            <mat-label>Remarks</mat-label>
                            <textarea matInput type="text" [value]="department.Remarks" style="resize:none"
                                readonly></textarea>
                        </mat-form-field>
                    </div>

                </div>
            </mat-card-content>
            <mat-card-content *ngIf="department.StatusCode!=='DepartmentActivityCompleted'">
                <span class="mat-checkbox-label">This activity is yet to be submitted...</span>
            </mat-card-content>

        </mat-expansion-panel>
    </div>
</mat-accordion>

<hr *ngIf="roleName==='HRM'">
<mat-card *ngIf="roleName==='HRM'">
  <mat-card-content>
    <form [formGroup]="clearenceForm" >
      <div class="row">
  
          <div class="col-md-6">
                              <mat-form-field appearance={{themeConfigInput}}>
                  <mat-label>Reason for Leaving Company</mat-label>
                  <mat-select formControlName="reasonId" name="reasonId">
                      <mat-option *ngFor="let reason of reasonList" [value]="reason.Id">{{reason.Name}}</mat-option>
                  </mat-select>
              </mat-form-field> 
          </div>
      </div>
        <div class="row">
            <div class="col-md-3">
                <mat-label>Client Impact<span class="text-danger">*</span></mat-label>
            </div>
            <div class="col-md-3">
                <mat-radio-group formControlName="impactOnClientDelivery" color="primary">
                    <mat-radio-button [value]=true>Yes</mat-radio-button>
                    <mat-radio-button [value]=false>No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-3">
                <mat-label id="example-radio-group-label">Eligible for Rehire<span class="text-danger">*</span>
                </mat-label>
            </div>
            <div class="col-md-3">
                <mat-radio-group formControlName="rehireEligibility" color="primary">
                    <mat-radio-button [value]=true>Yes</mat-radio-button>
                    <mat-radio-button [value]=false>No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance={{themeConfigInput}}>
                    <mat-label>Client Impact Details</mat-label>
                    <textarea placeholder="Client Impact Details" matInput
                    formControlName="impactOnClientDeliveryDetail" name="impactOnClientDeliveryDetail"></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance={{themeConfigInput}}>
                    <mat-label>Reason for Rehire</mat-label>
                    <textarea placeholder="Rehire Reason" matInput
                    formControlName="rehireEligibilityDetail" name="rehireEligibilityDetail"></textarea>
                </mat-form-field>
            </div>

        </div>
  </form>
  </mat-card-content>
</mat-card>

<hr [hidden]="roleName==='HRA'">
<mat-card [hidden]="roleName==='HRA'">
    <mat-card-content>
        <div class="row">
            <div class="col-md-12">
               <h3 style="width: 90%;" class="dividerHeading">Remarks</h3>
            </div>
        </div>
        <form [formGroup]="clearenceForm" >
        <div class="row">
            <div class="col-md-6">
            <mat-form-field  appearance={{themeConfigInput}}>
                <mat-label>By HR Manager</mat-label>
                <textarea ng-disbaled="roleName=='HRA' || roleName=='Operations Head'" placeholder="By HR Manager" matInput
                formControlName="remarksByHRM" name="remarksByHRM"></textarea>
            </mat-form-field>
            </div>
            <!-- <div *ngIf = "roleName=='HRA' || roleName=='Operation Head'" class="col-md-6">
                <mat-form-field  appearance={{themeConfigInput}}>
                    <mat-label>By Operation Head</mat-label>
                    <textarea placeholder="By Operation Head" matInput
                    formControlName="remarksByOpsHead" name="remarksByOpsHead"></textarea>
                </mat-form-field>
                </div> -->
        </div>
        <div *ngIf="false" class="row">
            <div class="col-md-12">
                <mat-form-field  appearance={{themeConfigInput}}>
                    <mat-label>By HRA</mat-label>
                    <textarea placeholder="By HRA" matInput
                    formControlName="remarksByHRA" name="remarksByHRA"></textarea>
                </mat-form-field>
                </div>
        </div>
        </form>
        <div class="row">
            <div *ngIf="previous=='/associateexit/associateexitinformation';else elseblock"> </div>
        <ng-template #elseblock>
        <div class="col-12 text-right">
            <button mat-flat-button color="primary" *ngIf="showButton" (click)="SubmitForAssociateExit()">{{btnLabel}}</button>
        </div>
    </ng-template>
    </div>

    </mat-card-content>
</mat-card>