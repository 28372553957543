<mat-card>
    <mat-card-content>
        <div class="col-lg-12 col-md-12 col-sm-12 p-0">
            <p>Location : &nbsp; <span>
                <mat-radio-button  value="GALAXY"
                disabled checked>GALAXY</mat-radio-button>
                <!-- <mat-radio-group aria-label="Select an option"
                    *ngFor="let location of parkingLocations" (change)="onPlaceChange($event)"
                    [(ngModel)]="selectedPlace">
                  
                    <mat-radio-button value="SILPARAMAM"
                        [disabled]="selectedPlace == 'GALAXY' && status == 'booked'">Silparamam</mat-radio-button>
                </mat-radio-group> -->
            </span>
        </p>
            
        </div>
        <div>
            <div class="row">
                <div class="col-lg-2 col-md-6 col-sm-12 m-auto">
                    Vehicle Number :
                </div>
                <div class="col-lg-8 col-md-6 col-sm-12 m-auto">
                    <mat-form-field appearance={{themeConfigInput}} class="feild-css">
                        <mat-label>Vehicle Number <span class="text-danger"> *</span></mat-label>
                        <input matInput type="text" [formControl]="vehicleNo" maxlength="10"
                            (keypress)="onlyAlphaNumeric($event)" oninput="this.value = this.value.toUpperCase()"
                            [readonly]="disableVehiceNumber">
                        <mat-error *ngIf="vehicleNo.hasError('required')">Vehicle Number is Required</mat-error>
                    </mat-form-field>
                    &nbsp;
                    <span>for <strong>{{today | date}}</strong></span>
                </div>
                <div class="col-lg-2 col-md-12 col-sm-12 m-auto">
                    <!-- <button mat-flat-button color="primary" (click)="bookSlot();" 
                    [disabled]="status == 'booked' || AvailableSlots <= 0" class="btn-css">Book Slot</button> -->
                    <button mat-flat-button color="primary" [hidden]="status == 'booked'" (click)="bookSlot()"
                        [disabled]="AvailableSlots <= 0" class="btn-css">Book Slot</button>
                    <button mat-flat-button color="primary" [hidden]="status != 'booked'" (click)="releaseSlot()"
                        class="btn-css">Release</button>

                </div>
                <!-- </div> -->

            </div>

            <div class="table-css">
                <table>
                    <th>Total Slots</th>
                    <th>Available Slots</th>
                    <th>Booked Slots</th>
                    <tr>
                        <td>{{TotalSlots}}</td>
                        <td>{{AvailableSlots}}</td>
                        <td>{{BookedSlots}}</td>
                    </tr>
                </table>
            </div>
            <div *ngIf="status == 'booked'" style="color : green" class="mt-2">
                <mat-icon class="emotican-css"> insert_emoticon</mat-icon>
                {{message}} at {{selectedPlace | titlecase}} at <strong>{{bookedTime}}</strong>
            </div>

            <div *ngIf="status != 'booked' && AvailableSlots <= 0" style="color : red" class="mt-2">
                {{slotsAvailbilityMsg}} at {{selectedPlace | titlecase}}
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let col of displayColumns" matColumnDef="{{col}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header= "{{col}}" class="font-weight-bold">{{ col }}
        </th>
        <td mat-cell *matCellDef="let element">{{element[col]}}</td>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>

                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data</td>
    </ng-container>

</table> -->