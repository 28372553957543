export class TagAssociateList{
    EmployeeId:number;    
    Id:number;
    ManagerId:number;
    TagListId:number;
    ProjectId: number;
    ProjectName : string;
    AllocatedPercentage : number;
    TagListName:string;
    EmployeeName:string;
    Designation:string;
    Available : number;
}