<mat-accordion>
    <mat-expansion-panel    [expanded]="step === 0" (opened)="step=0" (closed)="step=1">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon>filter_list</mat-icon> Filter Finance Report Data
        </mat-panel-title>
       </mat-expansion-panel-header>
  
       <form [formGroup]="myForm">
        <div class="row">
            <div class="col-md-4">
                <br>
                <mat-form-field appearance={{themeAppearence}}>
                    <mat-label>From Date<span style="color: red"> *</span></mat-label>

                    <input matInput [matDatepicker]="from" (click)="from.open()" formControlName="fromDate" style="cursor: pointer"
                        format='dd/mm/yy' (dateChange)="searchData.FromDate = $event.value"
                        [value]="searchData.FromDate" (paste)="$event.preventDefault()" [max]="lastDate" readonly>
                    <mat-datepicker-toggle matSuffix *ngIf="myForm.value.fromDate"
                        (click)="clearInput($event, 'fromDate')">
                        <mat-icon matDatepickerToggleIcon>close</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                    <mat-datepicker #from></mat-datepicker>
                    <mat-error *ngIf="myForm.controls.fromDate.hasError('required')">From Date is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <br>
                <mat-form-field appearance={{themeAppearence}}>
                    <mat-label>To Date<span style="color: red"> *</span></mat-label>
                    <input matInput [matDatepicker]="to" (click)="to.open()" formControlName="toDate" style="cursor: pointer"
                        format='dd/mm/yy' (dateChange)="searchData.ToDate = $event.value"
                        [value]="searchData.ToDate" [max]="lastDate" readonly (paste)="$event.preventDefault()">
                    <mat-datepicker-toggle matSuffix *ngIf="myForm.value.toDate"
                        (click)="clearInput($event, 'toDate')">
                        <mat-icon matDatepickerToggleIcon>close</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                    <mat-datepicker #to></mat-datepicker>
                    <mat-error *ngIf="myForm.controls.toDate.hasError('required')">To Date is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <br>

                <mat-form-field appearance="{{ themeAppearence }}">
                    <mat-label>Select Project</mat-label>
                    <input type="text" placeholder="Select Project" matInput
                    formControlName="ProjectId" name="ProjectId" [matAutocomplete]="auto">
                    <button class="clearFieldIcon" *ngIf="myForm.value.ProjectId"  (click)="clearField()" mat-button matSuffix
                    mat-icon-button aria-label="Clear">
                    <mat-icon>close</mat-icon>
                    </button>
                    <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="selectedChangeIds(item)"  (keyup.enter)="selectedChangeIds(item)" *ngFor="let item of filteredProject | async;" [value]="item.label">
                        {{item.label}}
                    </mat-option>
                </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="text-right buttons">
            <button type="button" mat-stroked-button (click)="clearFilter()">
                Clear</button>
            <button type="submit" class="mr-0" mat-flat-button color="primary" (click)="fetchResourceReport()">
                Apply</button>
        </div>
    </form>
    </mat-expansion-panel>
  </mat-accordion>

 
<mat-card class="mt-2">
    <div>
        <form [formGroup]="myForm">
            <div class="row">
                <div class="col-md-4">


                    <mat-form-field appearance="{{ themeAppearence }}">
                        <mat-label>{{selectedColumns.length}} columns selected</mat-label>
                        <mat-select [formControl]="myForm.controls.columnselect" placeholder="Select Options" multiple
                            name="optionsSelector" (selectionChange)="alterTable($event)">
                            <mat-option>
                                <ngx-mat-select-search formControlName="columnfilter" placeholderLabel="Search"
                                    noEntriesFoundLabel="No Record Found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
                            <mat-option *ngFor="let q of filteredBanksMulti | async" [value]="q" (click)="tosslePerOne(allSelected.viewValue)">
                                {{q.header}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <div class="mt-3">
                        <b>Total Records:</b>
                        <span>{{totalRecordsCount >0 ? totalRecordsCount: 0}}</span>
                    </div>
                </div>
                <br>
                <div class="col-md-4">
                        <div class="mt-3 text-right">
                            <!-- <button *ngIf="financeReport.length>0 && !isLoading"  mat-icon-button type="button" title="Export to Excel" (click)="exporter.exportTable('csv',{fileName: 'FinanceReport'})">
                                    <mat-icon>save_alt</mat-icon>
                                </button>  -->
                            <!-- <a *ngIf="financeReport.length>0 && !isLoading"
                                (click)="exporter.exportTable('xlsx',{fileName: 'FinanceReport'})" title="Export to Excel">
                                <div class="exportToExcel"></div>
                            </a> -->
                            <a *ngIf="financeReport.length>0 && !isLoading"
                                (click)="exportAsXLSX()" title="Export to Excel">
                                <div class="exportToExcel"></div>
                            </a>
                        </div>
                </div>
            </div>
            <div style="display: none;">
                <div class="table-responsive">
                    <table mat-table  [dataSource]="dataSource" matTableExporter  #exporter="matTableExporter" >
                        <ng-container *ngFor="let col of colsForExcel" matColumnDef="{{col.header}}">
                            <th mat-header-cell *matHeaderCellDef class="font-weight-bold">{{col.header}}
                            </th>
                            <td mat-cell *matCellDef="let element">{{element[col.field]}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displaycolsExcel"></tr>
    
                        <tr mat-row *matRowDef="let row; columns: displaycolsExcel;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data</td>
    
                    </table>
                </div>
            </div>
            <div class="table-responsive">
                <table mat-table #table [dataSource]="dataSource"  matSort >
                    <ng-container *ngFor="let col of selectedColumns" matColumnDef="{{col.header}}">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col.field}}" class="font-weight-bold">{{col.header}}
                        </th>
                        <td mat-cell *matCellDef="let element">{{element[col.field]}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displaycols"></tr>

                    <tr mat-row *matRowDef="let row; columns: displaycols;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data</td>

                </table>
            </div>
            <div class="row justify-content-center">
                <mat-paginator [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
            </div>
            <!-- <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div> -->
        </form>
    </div>
</mat-card>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" color="#585858" [fullScreen]="false" type="ball-clip-rotate-multiple"
    size="medium">
    <p class="loading">Loading ...</p>
</ngx-spinner>