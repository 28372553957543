<div mat-dialog-content>
<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <ng-container matColumnDef="SkillName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Skill </th>
            <td mat-cell *matCellDef="let element"> {{element.SkillName}} </td>
        </ng-container>
        <ng-container matColumnDef="Proficiency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Proficiency </th>
            <td mat-cell *matCellDef="let element"> {{element.ProficiencyLevelCode}} </td>
        </ng-container>       
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            class="example-element-row">           
        </tr>
        
    </table>
</div>
</div> 
<div mat-dialog-actions>
   
    <button class="primary" mat-button mat-dialog-close cdkFocusInitial (click)="closeDialog('Ok')">Ok</button>
    <button class="secondary" mat-button mat-dialog-close (click)="closeDialog('Cancel')" >Cancel</button>
  </div>
