<!-- <mat-card>
    <mat-card-title class="title-css">Upload File</mat-card-title>
    <mat-card-content>
        <div class="row col-md-12">
            <div class="col-md-4">
                <input type="file" (change)="upload(fileUpload.value)"  #fileUpload>
            </div>
            <div class="col-md-6">
                <button mat-flat-button color="primary" >
                    <mat-icon>upgrade</mat-icon> Upload
            </button>
            </div>
        </div>

      
    </mat-card-content>
</mat-card> -->
<div class="row col-md-12 template-css">
        <button mat-flat-button color="primary" (click)="downloadTemplate()">Download Template&nbsp;<mat-icon>download</mat-icon></button>
</div>

<div class="center">
    <p>Click on 'Browse File' to Select the File</p>

    <input type="file" class="file-input"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        (change)="onFileSelected($event)" #fileUpload (click)="$event.target.value=null" />

    <button mat-flat-button color="primary" class="btn-css" (click)="fileUpload.click()">Browse File</button>
    <div *ngIf="fileName" class="mt-3">
        <!-- <mat-expansion-panel style="margin-top:15px;" hideToggle (closed)="true"> -->
        <!-- <mat-expansion-panel-header> -->
        <mat-card>
            <div class="selected-file__header">
                <div class="selected-file__name">
                    {{fileName}}
                </div>
                <div class="selected-file__actions">
                    <button mat-flat-button (click)="uploadFile()" matTooltip="Upload" matTooltipPosition="above"
                        aria-label="Upload File"><mat-icon style="font-size: 20px;">upload</mat-icon></button>
                    <button mat-flat-button matTooltip="Cancel" matTooltipPosition="above" aria-label="Cancel File">
                        <mat-icon (click)="cancelUpload()" style="font-size: 20px;">clear</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card>

        <!-- </mat-expansion-panel-header> -->
        <!-- </mat-expansion-panel> -->
        <mat-progress-bar mode="indeterminate" *ngIf="fileUploaded">
        </mat-progress-bar>
    </div>

</div>