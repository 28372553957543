export class AssociateResignationData {
    EmployeeId : number;
    ReasonDescription: string;
    ProgramManagerName : string;
    ReportingManagerName : string;
    ProjectName : string;
    ResignationDate : string;
    IsBillable: string;
    IsCritical : string;
    EmployeeName : string;
    LastWorkingDate : string;
    Gender : string;
    IsResigned : boolean;
    IsLongLeave : boolean;
    LongLeaveStartDate : string;
    Reason: string;
    TentativeJoinDate : string;
}
