import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-project-teamlead',
  templateUrl: './view-project-teamlead.component.html',
  styleUrls: ['./view-project-teamlead.component.scss']
})
export class ViewProjectTeamleadComponent implements OnInit {

   ngOnInit(): void {
    }

}
