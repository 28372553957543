<mat-card>
    <mat-card-content>
      <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Name </th>
                <td mat-cell *matCellDef="let asstJoin"> {{asstJoin.Name}} </td>
              </ng-container>

              <ng-container matColumnDef="TechnologyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Technology </th>
                <td mat-cell *matCellDef="let asstJoin"> {{asstJoin.TechnologyName}} </td>
              </ng-container>

              <ng-container matColumnDef="DesignationName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Designation </th>
                <td mat-cell *matCellDef="let asstJoin"> {{asstJoin.DesignationName}} </td>
              </ng-container>

              <ng-container matColumnDef="DepartmentCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Department </th>
                <td mat-cell *matCellDef="let asstJoin"> {{asstJoin.DepartmentCode}} </td>
              </ng-container>

              <ng-container matColumnDef="JoinDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Joining Date </th>
                <td mat-cell *matCellDef="let asstJoin"> {{asstJoin.JoinDate}} </td>

              </ng-container>

              <ng-container matColumnDef="Hradvisor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Advisor </th>
                <td mat-cell *matCellDef="let asstJoin"> {{asstJoin.Hradvisor}} </td>
              </ng-container>

              <ng-container matColumnDef="Edit">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let asstJoin">
                  <mat-icon style="cursor: pointer" (click)="editAssociatejoining(asstJoin)">edit</mat-icon>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>
        </table>
    </div>
    <div class="row justify-content-center">
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
    </mat-card-content>
    </mat-card>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" color="#585858" [fullScreen]="false" type="ball-clip-rotate-multiple"
      size="medium">
      <p class="loading">Loading ...</p>
    </ngx-spinner>