<mat-card class="mb-2">
  <mat-card-content class="container">
    <form class="form-horizontal" [formGroup]="newRole">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-4 col-sm-auto">
          <mat-form-field appearance="outline"> 
            <mat-label>Associate List<span class="text-danger"> *</span> </mat-label>
            <input appDropDownSuggestion (keydown)="isKeydownPressed($event)" (keypress)="isKeyPressed($event)" (paste)="GetUserNames($event)"  [SearchList]="usersList" appRequirematch type="text" matInput
              [matAutocomplete]="auto" formControlName="associateName" appValidations appEmptySpacesValidations/>
            <button class="clearFieldIcon" *ngIf = "btnLabel == 'Save' && newRole.value.associateName"
              (click)="clearInput($event, 'associateName')" mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="GetUserRoles($event)">
              <mat-option disabled *ngIf="(newRole.get('associateName').hasError('invalidValue')&&this.searchValue.length>=3&&this.usersList.length===0)">
                No matching value found
              </mat-option>
              <mat-option *ngFor="let option of filteredOptionsName | async" [value]="option">
                {{ option.label }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="newRole.get('associateName').hasError('required')">
              Associate Name Required
            </mat-error>
            <mat-error *ngIf="newRole.get('associateName').hasError('requireMatch') && this.searchValue.length>=3">
              Select Associate Name from given options
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-auto">
          <mat-form-field appearance="outline">
            <mat-label>Role List<span class="text-danger"> *</span></mat-label>
            <input appRequirematch [SearchList]="rolesList" type="text" matInput [matAutocomplete]="role"
              formControlName="RoleId" appValidations appEmptySpacesValidations/>
            <button class="clearFieldIcon" *ngIf ="btnLabel == 'Save' && newRole.value.RoleId"
              (click)="clearInput($event, 'RoleId')" mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #role="matAutocomplete" [displayWith]="displayRolesFn">
              <mat-option disabled *ngIf="newRole.get('RoleId').hasError('invalidValue')">
                No matching value found
              </mat-option>
              <mat-option *ngFor="let option of filteredOptionsRole | async" [value]="option">
                {{ option.label }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="newRole.get('RoleId').hasError('required')">
              Role Required
            </mat-error>
            <mat-error *ngIf="newRole.get('RoleId').hasError('requireMatch')">
              Select Role from given options
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-auto">
          <mat-slide-toggle class="mt-3" formControlName="IsActive" color="primary"
            [disabled]="this.btnLabel == 'Save'">Is Active</mat-slide-toggle>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button mat-stroked-button (click)="Reset()">
            <mat-icon>close</mat-icon>Clear
          </button>
          <button mat-flat-button color="primary" (click)="SaveOrUpdateUserRoles()">
            {{btnLabel}}
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="RoleName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Role Name </th>
          <td mat-cell *matCellDef="let element"> {{element.RoleName}} </td>
        </ng-container>
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="cursor: pointer" (click)="SetEditObj(element)">edit</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>
      </table>
    </div>
    <div class="row justify-content-center">
      <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>