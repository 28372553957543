<div class="row">
        <div class="col-md-12">
                <h3 class="dividerHeading">Education Details</h3>
        </div>
</div>
<form *ngIf="educationAssociate" [formGroup]="educationAssociate" class="form-horizontal row justify-content-center">
        <div class="col-md-12">
                <div formArrayName="EducationArray">
                        <div class="table-responsive">
                                <table mat-table [dataSource]="qualifications">
                                        <ng-container matColumnDef="EducationalQualification">
                                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Qualification
                                                        <span class="text-danger">*</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                                                        <mat-form-field appearance={{themeAppearence}}>
                                                                <mat-select formControlName="EducationalQualification" (selectionChange)="this.qualifications[i].EducationalQualification = element['EducationalQualification'] = $event.value"
                                                                        (selectionChange)="checkqualification($event.value, i)">
                                                                        <mat-option *ngFor="let q of ddlQualifications" [value]="q.ValueId">
                                                                                {{q.ValueName}}
                                                                        </mat-option>
                                                                </mat-select>
                                                                <mat-error *ngIf="testArray.controls[i].get('EducationalQualification').hasError('required')">Qualification is Required</mat-error>
                                                        </mat-form-field>
                                                </td>
                                        </ng-container>

                                        <ng-container matColumnDef="ProgramTypeId">
                                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Program Type
                                                        <span class="text-danger"> *</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                                                        <mat-form-field appearance={{themeAppearence}}>
                                                                <mat-select formControlName="ProgramTypeId" (selectionChange)="this.qualifications[i].ProgramTypeId = element['ProgramTypeId'] = $event.value">
                                                                        <mat-option *ngFor="let option of programType" [value]="option.value">
                                                                                {{option.label}}
                                                                        </mat-option>
                                                                </mat-select>
                                                                <mat-error *ngIf="testArray.controls[i].get('ProgramTypeId').hasError('required')">Program Type is Required</mat-error>
                                                        </mat-form-field>
                                                </td>
                                        </ng-container>


                                        <ng-container matColumnDef="Specialization">
                                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Specialization
                                                        <span *ngIf="!is10th" class="text-danger"> *</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                                                        <mat-form-field appearance={{themeAppearence}}>
                                                                <input type="text" formControlName="Specialization" (keypress)="onlyStrings($event)" (paste)="$event.preventDefault()"
                                                                        [value]="element.Specialization" matInput (change)=" this.qualifications[i].Specialization= element['Specialization'] = $event.target.value">

                                                                <mat-error *ngIf="educationAssociate['controls']['EducationArray']['controls'][i]['controls']['Specialization'].hasError('required')">Specialization is required</mat-error>
                                                                <mat-error *ngIf="testArray.controls[i].get('Specialization').hasError('maxlength')">Maximum 100 characters are allowed</mat-error>
                                                                <mat-error *ngIf="educationAssociate['controls']['EducationArray']['controls'][i]['controls']['Specialization'].hasError && !testArray.controls[i].get('Specialization').hasError('required')">Only Alphabets are allowed</mat-error>

                                                        </mat-form-field>
                                                </td>
                                                <td></td>
                                        </ng-container>


                                        <ng-container matColumnDef="AcademicCompletedYear">
                                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Completed Year
                                                        <span class="text-danger"> *</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                                                        <mat-form-field appearance={{themeAppearence}}>
                                                                <mat-label>Choose a date</mat-label>
                                                                <input #myInput formControlName="AcademicCompletedYear" format='yyyy-MM-dd' (paste)="$event.preventDefault()" matInput [matDatepicker]="AcademicCompletedYear"
                                                                        [value]="element.AcademicCompletedYear"
                                                                        (dateChange)="element.AcademicCompletedYear = $event.value" readonly (click)="AcademicCompletedYear.open()" style="cursor: pointer;">
                                                                <mat-datepicker-toggle matSuffix *ngIf="testArray.controls[i].value.AcademicCompletedYear"
                                                                        (click)="clearInput($event, 'AcademicCompletedYear', i)">
                                                                        <mat-icon matDatepickerToggleIcon>close</mat-icon>
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker-toggle matSuffix [for]="AcademicCompletedYear"></mat-datepicker-toggle>
                                                                <mat-datepicker #AcademicCompletedYear></mat-datepicker>
                                                                <mat-error *ngIf="testArray.controls[i].get('AcademicCompletedYear').hasError('required')">Completed Year is required</mat-error>
                                                        </mat-form-field>
                                                </td>
                                                <td></td>
                                        </ng-container>


                                        <ng-container matColumnDef="Institution">
                                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Institute/University
                                                        <span class="text-danger"> *</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                                                        <mat-form-field appearance={{themeAppearence}}>
                                                                <input type="text" matInput formControlName="Institution" (keypress)="onlyStrings($event)" (paste)="$event.preventDefault()"
                                                                        [value]="element['Institution']" (change)=" this.qualifications[i].Institution= element['Institution'] = $event.target.value">
                                                                <mat-error *ngIf="testArray.controls[i].get('Institution').hasError('required')">Institute/University is required</mat-error>
                                                                <mat-error *ngIf="testArray.controls[i].get('Institution').hasError('maxlength')">Maximum 100 characters are allowed</mat-error>
                                                                <mat-error *ngIf="testArray.controls[i].get('Institution').hasError('pattern') && !testArray.controls[i].get('Institution').hasError('required')">Only Alphabets are allowed</mat-error>
                                                        </mat-form-field>
                                                </td>
                                                <td></td>
                                        </ng-container>


                                        <ng-container matColumnDef="Grade">
                                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Grade
                                                        <span class="text-danger"> *</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                                                        <mat-form-field appearance={{themeAppearence}}>
                                                                <mat-select formControlName="Grade" [value]="element['Grade']" selectionChange="gradeChange($event)" (selectionChange)="this.qualifications[i].Grade=element['Grade'] = $event.value">
                                                                        <mat-option *ngFor="let g of ddlGrades" value={{g.ValueId}}>{{g.ValueName}}
                                                                        </mat-option>
                                                                </mat-select>
                                                                <mat-error *ngIf="testArray.controls[i].get('Grade').hasError('required')">Grade is required</mat-error>
                                                        </mat-form-field>
                                                </td>
                                                <td></td>
                                        </ng-container>


                                        <ng-container matColumnDef="Marks">
                                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Marks/Percentage
                                                        <span class="text-danger"> *</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                                                        <mat-form-field appearance={{themeAppearence}}>
                                                                <input type="text" formControlName="Marks" (paste)="$event.preventDefault()" (keypress)="onlyNumbers($event)" matInput
                                                                        name="marks" [value]="element.Marks" maxlength="5" (change)=" this.qualifications[i].Marks= element['Marks'] = $event.target.value">
                                                                <mat-error *ngIf="testArray.controls[i].get('Marks').hasError('required')">Marks/Percentage is required</mat-error>
                                                                <mat-error *ngIf="testArray.controls[i].get('Marks').hasError('maxlength')">Maximum 5 characters are allowed</mat-error>
                                                                <mat-error *ngIf="testArray.controls[i].get('Marks').hasError('pattern') && !testArray.controls[i].get('Marks').hasError('required')">Only Numbers are allowed</mat-error>
                                                        </mat-form-field>
                                                </td>
                                        </ng-container>

                                        <div class="row">
                                                <div class="col-md-6">
                                                        <ng-container matColumnDef="Action">
                                                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Action </th>
                                                                <td mat-cell *matCellDef="let element; let i = index; let lst = last; let fst=first">
                                                                        <button  [disabled]="educationAssociate.controls.EducationArray.invalid"  mat-icon-button type="submit" (click)="onNewQualification()" [hidden]="!lst">
                                                                                <mat-icon>add</mat-icon>
                                                                        </button>
                                                                </td>

                                                        </ng-container>
                                                </div>
                                                <div class="col-md-6">
                                                        <ng-container matColumnDef="Delete">
                                                                <th mat-header-cell *matHeaderCellDef></th>
                                                                <td mat-cell *matCellDef="let element; let i = index; let lst = last; let fst=first">
                                                                        <button type="button" mat-icon-button
                                                                                (click)="onDelete(i)" [hidden]="fst&&lst">
                                                                                <mat-icon>delete</mat-icon>
                                                                                </button>
                                                                </td>
                                                        </ng-container>
                                                </div>
                                        </div>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                        </div>
                </div>
        </div>
        <div class="col-md-12">
                <section class="pull-right buttons">
                        <br>
                        <button mat-flat-button color="primary" type="submits" style="cursor:pointer;" *ngIf="type == 'new'" (click)="OnSubmit(qualifications)">
                                Save
                        </button>
                        <button mat-flat-button color="primary" type="submits" style="cursor:pointer;" *ngIf="type != 'new'" (click)="OnSubmit(qualifications)">
                                Update
                        </button>
                </section>
        </div>

</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" color="#585858" [fullScreen]="true" type="ball-clip-rotate-multiple"
size="medium">
<p class="loading">Loading ...</p>
</ngx-spinner>