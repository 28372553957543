import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skill-report',
  templateUrl: './skill-report.component.html',
  styleUrls: ['./skill-report.component.scss']
})
export class SkillReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
